import React from "react";
import { withStyles } from "@material-ui/core/styles";

import ReactMoment from "react-moment";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import { store } from "../firebase";

const styles = theme => ({
  success: {
    color: "green"
  },
  error: {
    color: "red"
  }
});

class Transactions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.refreshTransactions();
  }

  componentWillUnmount() {
    if (this.unsubscribeHomeTransactions) {
      this.unsubscribeHomeTransactions();
      this.unsubscribeHomeTransactions = undefined;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { home } = this.props;

    if (
      home &&
      home.id &&
      (prevProps.home === undefined || prevProps.home.id !== home.id)
    ) {
      this.refreshTransactions();
    }
  }

  refreshTransactions() {
    const { home } = this.props;

    if (this.unsubscribeHomeTransactions) {
      this.unsubscribeHomeTransactions();
      this.unsubscribeHomeTransactions = undefined;
    }

    if (home && home.id) {
      this.unsubscribeHomeTransactions = store.onGetHomeTransactions(
        home.id,
        transactions => {
          this.setState({ transactions });
        }
      );
    }
  }

  render() {
    const { classes, theme, home, balance, playBell } = this.props;
    const { transactions } = this.state;

    return (
      <List>
        {transactions &&
          transactions.map(transaction => (
            <ListItem key={transaction.id}>
              <ListItemIcon>
                {transaction.isPaid ? (
                  <CheckCircleIcon className={classes.success} />
                ) : (
                  <CancelIcon className={classes.error} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={`${
                  transaction.label ? transaction.label : "(Onbekend)"
                } (€${transaction.totalPrice})`}
                secondary={
                  <ReactMoment locale="nl-be" format="DD/MM/YYYY, HH:mm">
                    {transaction.timestamp.toDate()}
                  </ReactMoment>
                }
              />
            </ListItem>
          ))}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Transactions);
