import React from "react";

import { withStyles } from "@material-ui/core/styles";

import AuthorizedLink from "./AuthorizedLink";

import config from "../env";

const styles = theme => ({});

class AuthorizedAppLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {
    const {
      classes,
      home,
      endpoint,
      params,
      target,
      text,
      icon,
      onClick
    } = this.props;

    const link = `${config.functions.app}/${endpoint}`;

    return (
      <AuthorizedLink
        home={home}
        link={link}
        params={params}
        target={target}
        text={text}
        icon={icon}
        onClick={onClick}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(AuthorizedAppLink);
