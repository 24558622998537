import React from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemText,
  TextField,
  Button
} from "@material-ui/core";

import { toast } from "react-toastify";

import withAuthorization from "./withAuthorization";

import { store } from "../firebase";

const styles = theme => ({
  buttons: {
    justifyContent: "center"
  }
});

class RedeemCoupon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      isRedeeming: false
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  handleChange = key => event => {
    const val = event.target.value;

    const newState = {};
    newState[key] = val;

    this.setState(newState);
  };

  handleValidate = () => {
    const { home, auth } = this.props;
    const { code } = this.state;

    this.setState({ isRedeeming: true });

    if (auth && auth.user && auth.user.uid) {
      const toastId = toast("Kortingscode valideren...", { autoClose: false });
      store
        .redeemCoupon(auth.user.uid, home.id, code)
        .then(data => {
          toast.update(toastId, {
            render: `Kortingscode succesvol gevalideerd (account geüpdated naar ${
              data && data.tier && data.tier.label ? data.tier.label : "?"
            })`,
            type: toast.TYPE.INFO,
            autoClose: 3000
          });

          this.setState({ isRedeeming: false });
        })
        .catch(error => {
          toast.update(toastId, {
            render: `Er ging iets fout (${error.message})`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
          console.warn(error);

          this.setState({ code: "", isRedeeming: false });
        });
    } else {
      toast.error("Geen gemachtigde gebruiker gevonden");
    }
  };

  render() {
    const { classes } = this.props;
    const { code, isRedeeming } = this.state;

    return (
      <List>
        <ListItem>
          <TextField
            margin="dense"
            id="code"
            label="Kortingscode"
            helperText="Een kortingscode bestaat uit letters, cijfers en koppeltekens"
            fullWidth
            value={code}
            onChange={this.handleChange("code")}
          />
        </ListItem>

        <ListItem className={classes.buttons}>
          <Button
            color="primary"
            className={classes.button}
            onClick={this.handleValidate}
            disabled={code.length === 0 || isRedeeming}
          >
            Valideren
          </Button>
        </ListItem>
      </List>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(RedeemCoupon)
);
