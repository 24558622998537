import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import Linkify from "react-linkify";

const styles = theme => ({
  text: {
    fontSize: 15,
    lineHeight: "25px",
    [theme.breakpoints.up("700")]: {
      fontSize: 20,
      lineHeight: "30px"
    }
  }
});

class Multiline extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { text, variant, linkify, color, classes } = this.props;

    const componentDecorator = (href, text, key) => (
      <a
        href={href}
        key={key}
        target="_blank"
        style={{ fontWeight: "bold", textDecoration: "none", color }}
      >
        {text}
      </a>
    );

    return (
      <div>
        {text &&
          text.split("\n").map((item, key) => (
            <Typography
              key={key}
              variant={variant}
              className={classes.text}
              style={{ color }}
            >
              {linkify ? (
                <Linkify componentDecorator={componentDecorator}>
                  {item}
                </Linkify>
              ) : (
                { item }
              )}
              <br />
            </Typography>
          ))}
      </div>
    );
  }
}

export default withStyles(styles)(Multiline);
