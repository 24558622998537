import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Visit from "./Visit";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  visits: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    width: "100%",
    margin: "20px 0 10px 0"
  }
});

class Visits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {
    const { classes, home, title, visits } = this.props;

    return (
      <div className={classes.visits}>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        {visits && visits.length > 0 ? (
          visits.map(visit => (
            <Visit
              key={visit.id}
              home={home}
              id={visit.id}
              date={visit.date}
              time={visit.time}
              name={visit.name}
            />
          ))
        ) : (
          <Typography variant="overline">Geen bezoeken gevonden</Typography>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Visits);
