import React from "react";
import { withStyles } from "@material-ui/core/styles";

import ReactMoment from "react-moment";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import { store } from "../firebase";

const styles = theme => ({
  plus: {
    color: "green"
  },
  minus: {
    color: "red"
  }
});

class BalanceLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.refreshLog();

    /*const { home } = this.props;

    if (home && home.id) {
      this.refreshLog();
    }*/
  }

  componentWillUnmount() {
    if (this.unsubscribeHomeBalanceLog) {
      this.unsubscribeHomeBalanceLog();
      this.unsubscribeHomeBalanceLog = undefined;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { home } = this.props;

    if (
      home &&
      home.id &&
      (prevProps.home === undefined || prevProps.home.id !== home.id)
    ) {
      this.refreshLog();
    }

    /*if (
      ((home === undefined || home.id === undefined) &&
        prevProps.home !== undefined &&
        prevProps.home.id !== undefined) ||
      (home !== undefined &&
        home.id !== undefined &&
        (prevProps.home === undefined ||
          prevProps.home.id === undefined ||
          prevProps.home.id !== home.id))
    ) {
      this.refreshLog();
    }*/
  }

  refreshLog() {
    const { home } = this.props;

    if (this.unsubscribeHomeBalanceLog) {
      this.unsubscribeHomeBalanceLog();
      this.unsubscribeHomeBalanceLog = undefined;
    }

    if (home && home.id) {
      this.unsubscribeHomeBalanceLog = store.onGetHomeBalanceLog(
        home.id,
        log => {
          this.setState({ log });
        }
      );
    }
  }

  render() {
    const { classes, theme, home, balance, playBell } = this.props;
    const { log } = this.state;

    return (
      <List>
        {log &&
          log.map(entry => (
            <ListItem key={entry.id}>
              <ListItemIcon>
                {entry.amount > 0 ? (
                  <AddCircleIcon className={classes.plus} />
                ) : (
                  <RemoveCircleIcon className={classes.minus} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={`${entry.tier ? entry.tier.name : "(Onbekend)"}`}
                secondary={
                  <ReactMoment locale="nl-be" format="DD/MM/YYYY, HH:mm">
                    {entry.timestamp.toDate()}
                  </ReactMoment>
                }
              />
            </ListItem>
          ))}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(BalanceLog);
