import React from "react";
import { PropTypes } from "prop-types";
import ReactGA from "react-ga";

import config from "../env";

ReactGA.initialize(config.analytics.trackingId, {
  //debug: true,
  gaOptions: {
    appName: process.env.REACT_APP_NAME,
    appVersion: process.env.REACT_APP_VERSION
  }
});

let lastLocation;

const withAnalytics = Component => {
  class WithAnalytics extends React.Component {
    static contextTypes = {
      router: PropTypes.object
    };

    componentDidMount() {
      const { history } = this.props;
      this.sendPageView(history.location);
      history.listen(this.sendPageView);
    }

    sendPageView(location) {
      if (lastLocation && lastLocation.key === location.key) {
        return;
      }

      ReactGA.set({
        appName: process.env.REACT_APP_NAME,
        appVersion: process.env.REACT_APP_VERSION,
        page: location.pathname
      });
      ReactGA.pageview(location.pathname);

      lastLocation = location;
    }

    render() {
      return <Component {...this.props} />;
    }
  }
  return WithAnalytics;
};

export default withAnalytics;
