import React, { Component } from "react";

import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField
} from "@material-ui/core";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import { withStyles } from "@material-ui/core/styles";

import ReactMoment from "react-moment";

import { toast } from "react-toastify";

import { store } from "../firebase";

import AddVisitToCalendar from "../components/AddVisitToCalendar";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  textField: {
    width: "100%"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class BookVisitDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: ""
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleNameChange(event) {
    const name = event.target.value;

    this.setState({ name });
  }

  handleClose() {
    const { onClose } = this.props;

    onClose();
  }

  handleConfirm() {
    const { auth, home, date, time } = this.props;
    const { name } = this.state;

    if (
      auth === undefined ||
      auth.user === undefined ||
      auth.user.uid === undefined
    ) {
      return toast.error("Fout: geen geldige gebruiker gevonden");
    }
    if (name === undefined || name === "") {
      return toast.error(`Fout: naam niet ingevuld`);
    }

    const toastId = toast("Bezoek bewaren...", { autoClose: false });
    store
      .addVisit(auth.user.uid, home.id, date, time, name)
      .then(id => {
        toast.update(toastId, {
          render: (
            <div>
              <Typography>Bezoek bewaard</Typography>
              <AddVisitToCalendar
                home={home}
                visitId={id}
                caption="Toevoegen aan mijn agenda"
              />
            </div>
          ),
          type: toast.TYPE.SUCCESS,
          autoClose: false,
          closeOnClick: false
        });

        this.handleClose();
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);
      });
  }

  handleCancel() {
    const { onClose } = this.props;

    onClose();
  }

  render() {
    const { classes, open, date, time } = this.props;
    const { name } = this.state;

    return (
      <Dialog open={open} onClose={this.handleClose}>
        <DialogTitle>Wat is je naam?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vul hieronder je naam in en klik op bevestigen om je bezoek te
            bevestigen op{" "}
            <strong>
              <ReactMoment locale="nl-be" format="dddd D MMMM YYYY">
                {date}
              </ReactMoment>
            </strong>{" "}
            om <strong>{time}</strong>.
          </DialogContentText>
          <TextField
            className={classes.textField}
            label="Naam"
            margin="normal"
            variant="outlined"
            value={name}
            onChange={this.handleNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleCancel} color="primary">
            Annuleren
          </Button>
          <Button
            onClick={this.handleConfirm}
            color="primary"
            autoFocus
            disabled={name.length === 0}
          >
            Bevestigen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(BookVisitDialog));
