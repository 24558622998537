import React from "react";

import classnames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

const styles = theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    padding: "20px 0",
    [theme.breakpoints.down(800)]: {
      flexWrap: "wrap"
    }
  },
  screen: {
    [theme.breakpoints.up(800)]: {
      height: "calc(100vh - 205px)",
      overflowY: "auto"
    }
  },
  description: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 20,
    [theme.breakpoints.down(800)]: {
      textAlign: "center"
    },
    [theme.breakpoints.up(800)]: {
      textAlign: "right"
    }
  },
  alert: {
    fontWeight: "bold",
    color: "#f44336"
  },
  link: {
    textDecoration: "none"
  },
  leftComponents: {
    display: "inline-flex",
    justifyContent: "right"
  }
});

class SetupStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      children,
      title,
      description,
      linkText,
      linkUrl,
      alert,
      leftComponents
    } = this.props;

    return (
      <div className={classes.container}>
        <Container
          maxWidth="sm"
          className={classnames(classes.screen, classes.description)}
        >
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {description}
          </Typography>
          {alert && (
            <Typography variant="body1" gutterBottom className={classes.alert}>
              {alert}
            </Typography>
          )}
          {linkText && linkUrl && (
            <a href={linkUrl} target="_blank" className={classes.link}>
              <Typography variant="button" display="block" gutterBottom>
                {linkText}
              </Typography>
            </a>
          )}
          <div className={classes.leftComponents}>{leftComponents}</div>
        </Container>
        <Container maxWidth="sm" className={classes.screen}>
          {children}
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(SetupStep);
