import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import { withStyles } from "@material-ui/core/styles";

import ReactMoment from "react-moment";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  textField: {
    width: "100%"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ConfirmRemoveVisitDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleConfirm() {
    const { onConfirm } = this.props;

    onConfirm();
  }

  handleCancel() {
    const { onCancel } = this.props;

    onCancel();
  }

  render() {
    const { classes, open, date, time, label } = this.props;
    const { name } = this.state;

    return (
      <Dialog open={open} onClose={this.handleClose}>
        <DialogTitle>Bevestig verwijderen van bezoek</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ben je zeker dat je het bezoek met <strong>{name}</strong> op{" "}
            <strong>
              <ReactMoment locale="nl-be" format="dddd D MMMM YYYY">
                {date}
              </ReactMoment>
            </strong>{" "}
            om <strong>{time}</strong> wil verwijderen?
            <br />
            <br />
            Opgelet! Je bezoek wordt niet automatisch op de hoogte gebracht van
            je annulatie.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleCancel} color="primary">
            Annuleren
          </Button>
          <Button onClick={this.handleConfirm} color="primary" autoFocus>
            Verwijderen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(ConfirmRemoveVisitDialog));
