import React, { Component } from "react";
import classNames from "classnames";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DirectionsIcon from "@material-ui/icons/Directions";

import { Button, Typography } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import animateScrollTo from "animated-scroll-to";

import { generateRouteUrl } from "../utils";
import Multiline from "./Multiline";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh"
  },
  backgroundAsColor: {
    backgroundColor: theme.palette.secondary.main
  },
  backgroundAsImage: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover"
  },
  nameAsText: {
    fontSize: 80,
    [theme.breakpoints.up("700")]: {
      fontSize: 120
    },
    color: theme.palette.primary.main
  },
  nameAsImage: {
    width: "100%",
    height: 100,
    [theme.breakpoints.up("700")]: {
      height: 120
    },
    marginTop: 100,
    backgroundSize: "auto 100% ",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center"
  },
  logo: {
    width: "100%",
    height: 200,
    [theme.breakpoints.up("700")]: {
      height: 450
    },
    marginLeft: -27,
    height: "100%",
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom"
  },
  description: {},
  colorPrimary: {
    color: theme.palette.primary.main
  },
  colorWhite: {
    color: "white"
  },
  directions: {
    position: "absolute",
    bottom: 20,
    left: 20
  },
  makeVisit: {
    position: "absolute",
    bottom: 20,
    right: 20,
    color: "white"
  },
  canMakeVisits: {},
  cantMakeVisits: {},
  hideOnMobile: {
    [theme.breakpoints.down("300")]: {
      display: "none"
    }
  }
});

class Splash extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.routeDirections = this.routeDirections.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  routeDirections() {
    const { home } = this.props;

    if (home && home.address) {
      const { street, number, box, zip, city } = home.address;
      window.open(generateRouteUrl(street, number, box, zip, city), "_blank");
    }
  }

  scrollDown() {
    animateScrollTo(document.querySelector(".visits"));
  }

  render() {
    const { classes, home, assets, canMakeVisits } = this.props;

    const componentDecorator = (href, text, key) => (
      <a
        href={href}
        key={key}
        target="_blank"
        style={{ fontWeight: "bold", textDecoration: "none" }}
      >
        {text}
      </a>
    );

    return (
      <div
        className={classNames(
          classes.root,
          assets && assets.splashBackground
            ? classes.backgroundAsImage
            : classes.backgroundAsColor
        )}
        style={{
          marginBottom: canMakeVisits ? 20 : 0,
          backgroundImage:
            assets && assets.splashBackground
              ? `url('${assets.splashBackground.url}')`
              : undefined
        }}
      >
        {assets && assets.splashName ? (
          <div
            className={classes.nameAsImage}
            style={{ backgroundImage: `url('${assets.splashName.url}')` }}
          ></div>
        ) : (
          <div className={classNames(classes.nameAsText, "apply-font")}>
            {home.name}
          </div>
        )}
        {home && home.params && home.params.description && (
          <div
            className={classNames(
              classes.description,
              assets && assets.splashBackground
                ? classes.colorWhite
                : classes.colorPrimary
            )}
          >
            <Multiline
              text={home.params.description}
              variant="overline"
              linkify={true}
              color={
                assets && assets.splashBackground
                  ? "white"
                  : home && home.colors
                  ? home.colors.primary
                  : undefined
              }
            />
          </div>
        )}
        {assets && assets.splashLogo && (
          <div
            className={classes.logo}
            style={{ backgroundImage: `url('${assets.splashLogo.url}')` }}
          ></div>
        )}
        <Button
          className={classNames(
            classes.directions,
            assets && assets.splashBackground
              ? classes.colorWhite
              : classes.colorPrimary
          )}
          onClick={this.routeDirections}
        >
          <DirectionsIcon />
          <div className={classes.hideOnMobile}>Adres</div>
        </Button>
        <div className={classes.makeVisit}>
          {canMakeVisits ? (
            <Button
              onClick={this.scrollDown}
              className={classNames(
                classes.canMakeVisits,
                assets && assets.splashBackground
                  ? classes.colorWhite
                  : classes.colorPrimary
              )}
            >
              <div className={classes.hideOnMobile}>Langskomen</div>
              <KeyboardArrowDownIcon />
            </Button>
          ) : (
            <Typography
              variant="overline"
              className={classNames(
                classes.cantMakeVisits,
                assets && assets.splashBackground
                  ? classes.colorWhite
                  : classes.colorPrimary
              )}
            >
              Bezoek maken niet mogelijk
            </Typography>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Splash);
