import React from "react";
import { withRouter } from "react-router-dom";

import AuthContext from "./authContext";

import { Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

import { firebase, auth } from "../firebase";

const withCustomerAuthorization = authCondition => Component => {
  class WithCustomerAuthorization extends React.Component {
    componentDidMount() {
      firebase.auth.onAuthStateChanged(user => {
        if (user === null) {
          auth
            .doSignInAnonymously()
            .then(() => {})
            .catch(error => {
              console.warn(error);
            });
        }
      });
    }

    /*render() {
      return (
        <Component />
      );
    }*/

    render() {
      return (
        <AuthContext.Consumer>
          {auth =>
            auth && auth.user && <Component auth={auth} {...this.props} />
          }
        </AuthContext.Consumer>
      );
    }
  }

  return withRouter(WithCustomerAuthorization);
};

export default withCustomerAuthorization;
