import React from "react";
import classNames from "classnames";

import { Typography, Fab } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

import { withStyles } from "@material-ui/core/styles";

import config from "../env";

import Moment from "moment";

import Visits from "../components/Visits";

const styles = theme => ({
  root: {},
  homeInfo: {
    position: "fixed",
    zIndex: 0,
    top: 68,
    left: 0,
    width: "100vw"
  },
  homeInfoSpacing: {
    top: 90
  },
  homeVisits: {
    position: "relative",
    zIndex: 10,
    marginTop: 20,
    [theme.breakpoints.down("500")]: {
      marginTop: 60
    }
  },
  homeVisitsSpacing: {
    marginTop: 30
  },
  noUpcomingVisits: {
    display: "block",
    marginTop: 200
  },
  link: {
    marginLeft: 5,
    fontWeight: "bold",
    textDecoration: "none",
    textTransform: "none",
    color: "black"
  },
  upcomingVisitsInfo: {
    display: "block"
  }
});

class HomeVisitsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, theme, home, menu, upcomingVisits } = this.props;

    let homeUrl;
    if (home && home.locator) {
      homeUrl = `https://${config.hosting.domain}/${home.locator}`;
    }

    let todayVisits, tomorrowVisits, otherVisits;
    if (upcomingVisits) {
      const today = Moment().format("YYYY-MM-DD");
      const tomorrow = Moment()
        .add(1, "day")
        .format("YYYY-MM-DD");

      todayVisits = upcomingVisits.filter(visit => {
        return visit.date === today;
      });
      tomorrowVisits = upcomingVisits.filter(visit => {
        return visit.date === tomorrow;
      });
      otherVisits = upcomingVisits.filter(visit => {
        return visit.date > tomorrow;
      });
    }

    return (
      <div className={classes.root}>
        {homeUrl && (
          <div className={classes.homeInfo}>
            <Typography variant="overline">
              Link naar je publieke pagina:
              <a className={classes.link} href={homeUrl} target="_blank">
                {homeUrl}
              </a>
            </Typography>
          </div>
        )}
        <div className={classes.homeVisits}>
          <Visits home={home} title="Vandaag" visits={todayVisits} />
          <Visits home={home} title="Morgen" visits={tomorrowVisits} />
          <Visits home={home} title="Later" visits={otherVisits} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HomeVisitsPage);
