import React from "react";
import classNames from "classnames";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tabs,
  Tab,
  TabPanel,
  Button
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import Moment from "moment-timezone";

import { toast } from "react-toastify";

import { store } from "../firebase";

import { jsDateToTimeString } from "../utils";

import AddAvailabilityWithCalendar from "../components/AddAvailabilityWithCalendar";
import AddAvailabilityWithRepetition from "../components/AddAvailabilityWithRepetition";

const styles = theme => ({
  pickers: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down(732)]: {
      flexWrap: "wrap"
    }
  },
  picker: {
    margin: 15
  },
  times: {
    [theme.breakpoints.down(732)]: {
      width: 410
    }
  }
});

class AddAvailabilityDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
      dates: [],
      times: [
        Moment()
          .startOf("day")
          .add(14, "hours")
          .toDate()
      ]
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.props;
    const wasOpen = prevProps !== undefined && prevProps.open;
    
    if (open && !wasOpen) {
      this.clearAvailability();
    }
  }

  clearAvailability = tab => {
    this.setState({
      dates: [],
      times: [
        Moment()
          .startOf("day")
          .add(14, "hours")
          .toDate()
      ],
      tab: tab === undefined ? 0 : tab
    });
  };

  handleTabChange = (event, newTab) => {
    this.clearAvailability(newTab);
  };

  handleDatesAndTimesChange = datesAndTimes => {
    const { dates, times } = datesAndTimes;
    this.setState({ dates, times });
  };

  handleAddAvailability = () => {
    const { home } = this.props;
    const { dates, times } = this.state;

    const timesAsString = times
      .map(time => {
        return jsDateToTimeString(time);
      })
      .reduce((unique, item) => {
        return unique.includes(item) ? unique : [...unique, item];
      }, []);

    const availability = dates.map(date => {
      return { date, times: [...timesAsString] };
    });

    if (availability.length === 0) {
      return toast.error("Geen data gevonden om toe te voegen");
    }

    const toastId = toast("Data toevoegen...", { autoClose: false });
    store
      .addAvailability(home.id, availability)
      .then(() => {
        toast.update(toastId, {
          render: "Data toegevoegd",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
        this.handleClose();
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);
      });
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { classes, open, home } = this.props;
    const { tab, dates, times } = this.state;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="AddItem"
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={window.innerWidth < 515}
      >
        <DialogTitle>Nieuwe beschikbaarheid toevoegen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Voeg hieronder dagen en tijdstippen toe waarop je bezoek wenst te
            ontvangen.
          </DialogContentText>
          <Tabs
            value={tab}
            onChange={this.handleTabChange}
            aria-label="simple tabs example"
          >
            <Tab label="Kalender" />
            <Tab label="Herhalende beschikbaarheden" />
          </Tabs>
          {tab === 0 && (
            <AddAvailabilityWithCalendar
              home={home}
              dates={dates}
              times={times}
              onChange={this.handleDatesAndTimesChange}
            />
          )}
          {tab === 1 && (
            <AddAvailabilityWithRepetition
              home={home}
              dates={dates}
              times={times}
              onChange={this.handleDatesAndTimesChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            Annuleren
          </Button>
          <Button onClick={this.handleAddAvailability} color="primary">
            Toevoegen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AddAvailabilityDialog);
