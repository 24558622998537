import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import { toast } from "react-toastify";

const styles = (theme) => ({
  amount: {
    display: "flex",
    alignItems: "center",
  },
  resize: {
    display: "inline-block",
    marginTop: 3,
    width: 35,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.54)",
  },
  amountText: {
    marginBottom: 4,
  },
  controlAmountButton: {
    margin: "0 3px 0 3px",
  },
  icon: {
    display: "flex",
    marginRight: 10,
  },
  unit: {
    display: "inline-block",
    marginRight: 15,
    textTransform: "uppercase",
  },
});

class SelectAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
    };

    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleDecreaseAmount = this.handleDecreaseAmount.bind(this);
    this.handleIncreaseAmount = this.handleIncreaseAmount.bind(this);
  }

  componentDidMount() {
    const { amount } = this.props;

    if (
      amount !== undefined &&
      amount !== null &&
      amount !== this.state.amount
    ) {
      this.setState({ amount });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { amount } = this.props;

    if (
      amount !== undefined &&
      amount !== null &&
      amount !== this.state.amount
    ) {
      this.setState({ amount });
    }
  }

  componentWillUnmount() {}

  updateAmount(amount) {
    const { min, max, onChange } = this.props;

    const minAmount = min === undefined ? 0 : min;

    if ((amount >= minAmount && max === undefined) || amount <= max) {
      this.setState({ amount });

      if (onChange) {
        onChange(amount);
      }
    }
  }

  handleAmountChange(event) {
    if (event.target.value) {
      try {
        const amount = parseInt(event.target.value);

        if (!isNaN(amount)) {
          this.updateAmount(amount);
        }
      } catch (error) {
        if (error) {
          toast.error(error.message);
        }
      }
    }
  }

  handleDecreaseAmount() {
    const { step, min } = this.props;
    let { amount } = this.state;

    if (step !== undefined) {
      amount -= step;
    } else {
      amount--;
    }

    this.updateAmount(amount);
  }

  handleIncreaseAmount() {
    const { step, max } = this.props;
    let { amount } = this.state;

    if (step !== undefined) {
      amount += step;
    } else {
      amount++;
    }

    this.updateAmount(amount);
  }

  render() {
    const { classes, unit, icon, step, allowInput } = this.props;
    const { amount } = this.state;

    return (
      <div className={classes.amount}>
        {icon && <div className={classes.icon}>{icon}</div>}
        <TextField
          color="secondary"
          id="begin"
          type="number"
          value={amount}
          className={classes.amountText}
          onChange={this.handleAmountChange}
          label=""
          disabled={allowInput === undefined ? true : !allowInput}
          InputProps={{
            step: step === undefined ? 1 : step,
            disableUnderline: true,
            classes: {
              input: classes.resize,
            },
          }}
        />
        {unit && <span className={classes.unit}>{unit}</span>}
        <Fab
          variant="extended"
          size="small"
          color="secondary"
          aria-label="-"
          className={classes.controlAmountButton}
          onClick={this.handleDecreaseAmount}
        >
          <RemoveIcon />
        </Fab>
        <Fab
          variant="extended"
          size="small"
          color="primary"
          aria-label="+"
          className={classes.controlAmountButton}
          onClick={this.handleIncreaseAmount}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

export default withStyles(styles)(SelectAmount);
