import React from "react";

import { withStyles } from "@material-ui/core/styles";

import TodayIcon from "@material-ui/icons/Today";

import AuthorizedAppLink from "./AuthorizedAppLink";

const styles = theme => ({});

class AddVisitToCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes, home, visitId, caption } = this.props;

    return (
      <AuthorizedAppLink
        home={home}
        endpoint="invite"
        params={{ homeId: home.id, visitId }}
        icon={<TodayIcon />}
        text={caption}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddVisitToCalendar);
