import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Card, CardHeader, CardContent } from "@material-ui/core";

import withHomeAuthorization from "../components/withHomeAuthorization";

import EditParams from "../components/EditParams";
import EditAddress from "../components/EditAddress";
import EditFont from "../components/EditFont";
import EditColors from "../components/EditColors";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  card: {
    margin: 10,
    width: "100%",
    [theme.breakpoints.up(800)]: {
      maxWidth: 350
    }
  }
});

class HomeEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  addItem() {}

  render() {
    const {
      classes,
      theme,
      home,
      availability,
      assets,
      isPremium
    } = this.props;

    return (
      <div className={classes.container}>
        <Card className={classes.card}>
          <CardHeader title="Instellingen" />
          <CardContent>
            <EditParams home={home} isPremium={isPremium} />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="Adres" />
          <CardContent>
            <EditAddress home={home} showMapsButton={true} />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="Lettertype" />
          <CardContent>
            <EditFont home={home} />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="Kleuren" />
          <CardContent>
            <EditColors home={home} />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withHomeAuthorization()(
  withStyles(styles, { withTheme: true })(HomeEditPage)
);
