import React from "react";

import { Fab } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { withStyles } from "@material-ui/core/styles";

import { toast } from "react-toastify";

import withAuthorization from "./withAuthorization";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  fab: {
    margin: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
});

class AuthorizedLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.openLink = this.openLink.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  openLink() {
    const { auth, link, params, target, onClick } = this.props;

    if (auth && auth.user) {
      auth.user
        .getIdToken()
        .then(token => {
          let url = `${link}?token=${token}`;
          if (params) {
            for (const key in params) {
              url += `&${encodeURIComponent(key)}=${encodeURIComponent(
                params[key]
              )}`;
            }
          }

          console.info(url);
          if (token) {
            window.open(url, target ? target : "_blank");
            //window.open.href = url;
          } else {
            toast.error("Geen geldig token gevonden voor deze gebruiker");
          }
        })
        .catch(error => {
          toast.error("Fout bij het ophalen van het token voor deze gebruiker");
          console.warn(error);
        });
    } else {
      toast.error("Geen gemachtigde gebruiker gevonden");
    }

    if (onClick) {
      onClick();
    }
  }

  render() {
    const { classes, home, text, icon } = this.props;

    return (
      <Fab
        variant="extended"
        aria-label="download"
        className={classes.fab}
        onClick={this.openLink}
      >
        <div className={classes.extendedIcon}>
          {icon ? icon : <ArrowDownwardIcon />}
        </div>
        {text}
      </Fab>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(AuthorizedLink)
);
