export const PUBLIC_INFO = "/";
export const PUBLIC_BOOK_VISIT = "/:locator?";
export const HOME_ROUTE = "/home/*";
export const HOME_REGISTER = "/home/register";
export const HOME_SIGN_IN = "/home/signin";
export const HOME_SETUP = "/home/setup";
export const HOME_VISITS = "/home/visits";
export const HOME_AVAILABILITY = "/home/availability";
export const HOME_EDIT = "/home/edit";
export const HOME_PROMO = "/home/promo";
export const HOME_BALANCE = "/home/balance";
