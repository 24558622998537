import React, { Component } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 20px"
  },
  imageAsset: {
    display: "block",
    height: 20,
    marginRight: 5
  },
  referringUrl: {
    textDecoration: "none"
  },
  callToAction: {
    margin: 20
  }
});

class Promo extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      catchPhrase,
      imageAsset,
      callToAction,
      referringUrl
    } = this.props;

    return (
      <div className={classes.container}>
        <Typography className={classes.catchPhrase} variant="h6">
          Baby op komst?
        </Typography>
        <a
          className={classes.referringUrl}
          href="https://babybezoek.be"
          target="_blank"
        >
          <Button
            className={classes.callToAction}
            variant="outlined"
            size="large"
          >
            <img
              className={classes.imageAsset}
              src="/assets/logo-inverted.png"
            />
            Maak nu gratis gebruik van Babybezoek
          </Button>
        </a>
      </div>
    );
  }
}

export default withStyles(styles)(Promo);
