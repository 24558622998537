import React from "react";
import { withRouter } from "react-router-dom";

import AuthContext from "./authContext";

import { Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

const withAuthorization = authCondition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      /*firebase.auth.onAuthStateChanged(authUser => {
        if (!authCondition(authUser)) {
          this.props.history.push(routes.SIGN_IN);
        }
      });*/
    }

    /*render() {
      return (
        <Component />
      );
    }*/

    render() {
      return (
        <AuthContext.Consumer>
          {auth =>
            auth && auth.user ? (
              <Component auth={auth} {...this.props} />
            ) : (
              <Redirect to={routes.HOME_SIGN_IN} />
            )
          }
        </AuthContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorization);
};

export default withAuthorization;
