import React, { Component } from "react";

import { List, ListItem } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import SelectDate from "./SelectDate";
import SelectTime from "./SelectTime";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  list: {
    display: "flex",
    flexDirection: "row"
  }
});

class SelectDateAndTime extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  handleDateChange(date) {
    const { time, onChange } = this.props;

    if (date) {
      onChange({ date, time });
    }
  }

  handleTimeChange(time) {
    const { date, onChange } = this.props;

    if (time) {
      onChange({ date, time });
    }
  }

  render() {
    const { classes, date, time } = this.props;

    return (
      <div className={classes.root}>
        <List className={classes.list}>
          <ListItem>
            <SelectDate date={date} onChange={this.handleDateChange} />
          </ListItem>
          <ListItem>
            <SelectTime time={time} onChange={this.handleTimeChange} />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(SelectDateAndTime);
