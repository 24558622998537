import React from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Switch
} from "@material-ui/core";

import { toast } from "react-toastify";

import { store } from "../firebase";

import SelectAmount from "./SelectAmount";
import PremiumLabel from "./PremiumLabel";

const styles = theme => ({
  payconiqMerchantLink: {
    maxWidth: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whitespace: "nowrap"
  },
  buttons: {
    justifyContent: "center"
  }
});

class EditParams extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        name: "",
        description: "",
        minDaysInAdvance: 0,
        hideVisitsForPublic: false,
        hideAds: false,
        showAddVisitToCalendar: false,
        sendVisitsReminder: false
      }
    };
  }

  initParams() {
    const { home, isPremium } = this.props;

    const params = {
      name: "",
      description: "",
      minDaysInAdvance: 0,
      hideVisitsForPublic: false,
      hideAds: false,
      showAddVisitToCalendar: false,
      sendVisitsReminder: false
    };

    if (home) {
      if (home.name) {
        params["name"] = home.name;
      }

      if (home.params) {
        if (home.params.description) {
          params["description"] = home.params.description;
        }
        if (home.params.minDaysInAdvance) {
          params["minDaysInAdvance"] = home.params.minDaysInAdvance;
        }
        if (home.params.hideVisitsForPublic) {
          params["hideVisitsForPublic"] = home.params.hideVisitsForPublic;
        }
      }

      if (home.capabilities) {
        params["hideAds"] = isPremium === true; // Not editable
        params["showAddVisitToCalendar"] = isPremium === true; // Not editable
        if (home.capabilities.sendVisitsReminder) {
          params["sendVisitsReminder"] = home.capabilities.sendVisitsReminder;
        }
      }
    }

    this.setState({ params });
  }

  componentDidMount() {
    this.initParams();
  }

  componentDidUpdate(prevProps, prevState) {
    const { home } = this.props;
    if (
      (prevProps.home === undefined || prevProps.home.params === undefined) &&
      home &&
      home.params
    ) {
      this.initParams();
    }
  }

  componentWillUnmount() {}

  handleChange = key => event => {
    let { params } = this.state;
    const val = event.target.value;

    params[key] = val;

    this.setState({ params });
  };

  handleMinDaysInAdvanceChange = amount => {
    let { params } = this.state;
    const { minDaysInAdvance } = params;

    const parsedAmount = parseInt(amount);
    const parsedMinDaysInAdvance = parseInt(minDaysInAdvance);

    if (!isNaN(parsedAmount) && parsedAmount >= 0) {
      params.minDaysInAdvance = parsedAmount;
    } else if (isNaN(parsedMinDaysInAdvance) || parsedMinDaysInAdvance < 0) {
      params.minDaysInAdvance = 0;
    }

    this.setState({ params });
  };

  handleHideVisitsForPublicChange = event => {
    let { params } = this.state;

    params.hideVisitsForPublic = event.target.checked === true;

    this.setState({ params });
  };

  handlesendVisitsReminderChange = event => {
    let { params } = this.state;

    params.sendVisitsReminder = event.target.checked === true;

    this.setState({ params });
  };

  handleSave = () => {
    const { home, isPremium } = this.props;
    const { params } = this.state;

    const toastId = toast("Instellingen bewaren...", { autoClose: false });

    const promises = [];
    if (home.name !== params.name) {
      promises.push(store.setHomeName(home.id, params.name));
    }
    promises.push(
      store.setHomeParams(home.id, {
        description: params.description,
        minDaysInAdvance: params.minDaysInAdvance,
        hideVisitsForPublic: params.hideVisitsForPublic
      })
    );
    if (isPremium) {
      promises.push(
        store.setHomeCapabilities(home.id, {
          hideAds: params.hideAds,
          showAddVisitToCalendar: params.showAddVisitToCalendar,
          sendVisitsReminder: params.sendVisitsReminder
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        toast.update(toastId, {
          render: "Instellingen bewaard",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);
      });
  };

  handleCancel = () => {
    this.initParams();
  };

  render() {
    const { classes, home, isPremium } = this.props;
    const { params } = this.state;
    const {
      name,
      description,
      minDaysInAdvance,
      hideVisitsForPublic,
      sendVisitsReminder
    } = params;

    return (
      <div>
        <List>
          <ListItem>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Naam van je kindje"
              helperText="De naam van je kindje wordt getoond op je publieke pagina"
              fullWidth
              value={name}
              onChange={this.handleChange("name")}
            />
          </ListItem>
          <ListItem>
            <TextField
              margin="dense"
              id="description"
              label="Extra informatie"
              multiline
              rows="4"
              helperText="Informatie zoals gewicht en lengte van je kindje. Naam van ouders, peter en meter. Pamperrekening of geboortelijst."
              fullWidth
              value={description}
              onChange={this.handleChange("description")}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Minimum aantal dagen op voorhand"
              secondary="Minimum aantal dagen op voorhand dat een bezoeker een bezoek kan vastleggen"
            />
            <SelectAmount
              amount={minDaysInAdvance}
              onChange={amount => {
                this.handleMinDaysInAdvanceChange(amount);
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Bezoeken verbergen"
              secondary="Verberg gepland bezoek (tijdstip en naam) op mijn publieke pagina"
            />
            <Switch
              onChange={this.handleHideVisitsForPublicChange}
              checked={hideVisitsForPublic}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <span>
                  Herinneringen
                  {!isPremium && <PremiumLabel />}
                </span>
              }
              secondary="Stuur me één dag op voorhand herinneringen van mijn bezoek"
            />
            <Switch
              onChange={this.handlesendVisitsReminderChange}
              checked={sendVisitsReminder}
              disabled={!isPremium}
            />
          </ListItem>
          <ListItem className={classes.buttons}>
            <Button
              color="primary"
              className={classes.button}
              onClick={this.handleSave}
            >
              Bewaren
            </Button>
            <Button
              color="secondary"
              className={classes.button}
              onClick={this.handleCancel}
            >
              Annuleren
            </Button>
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditParams);
