import React, { Component } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

const styles = (theme) => ({});

class PremiumAdvantages extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography>Alle voordelen op een rijtje:</Typography>
        <ul style={{ textAlign: "left" }}>
          <li>
            Je wordt via mail automatisch herinnerd aan je volgende bezoeken.
          </li>
          <li>
            Je hebt de mogelijkheid om de bezoeken toe te voegen aan je
            persoonlijke agenda.
          </li>
          <li>
            Er worden geen promotieberichten getoond op je publieke pagina.
          </li>
        </ul>
      </div>
    );
  }
}

export default withStyles(styles)(PremiumAdvantages);
