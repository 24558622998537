import Moment from "moment";

export const findInArrayByPropVal = (arr, prop, val) => {
  const findByPropVal = element => {
    return element && element[prop] === val;
  };

  if (arr) {
    return arr.find(findByPropVal);
  }
};

export const findInArrayById = (arr, id) => {
  return findInArrayByPropVal(arr, "id", id);
};

export const filterArrayWithPropBetween = (
  arr,
  prop,
  begin,
  end,
  includeBegin,
  includeEnd
) => {
  if (arr) {
    return arr.filter(element => {
      return (
        element &&
        element[prop] &&
        (includeBegin ? element[prop] >= begin : element[prop] > begin) &&
        (includeEnd ? element[prop] <= end : element[prop] < end)
      );
    });
  }
};

export const getRandomFromArray = arr => {
  if (arr && arr.length > 0) {
    return arr[Math.floor(Math.random() * arr.length)];
  }
};

export const makeCancelable = promise => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
};

export const isiOS = () => {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
};

export const jsDateToDateString = jsDate => {
  return Moment(jsDate).format("YYYY-MM-DD"); //jsDate.toISOString().substr(0, 10);
};

export const jsDateToTimeString = jsDate => {
  return Moment(jsDate).format("HH:mm"); //jsDate.toISOString().substr(0, 10);
};

export const jsDateToDateTimeString = jsDate => {
  return (
    jsDate
      .toISOString()
      .substr(0, 19)
      .replace(/-/g, "")
      .replace(/:/g, "") + "Z"
  );
};

export const dateStringToJSDate = dateString => {
  return new Date(`${dateString}T00:00:00Z`);
};

export const isValidDate = jsDate => {
  if (Object.prototype.toString.call(jsDate) === "[object Date]") {
    // it is a date
    if (isNaN(jsDate.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return false;
    } else {
      // date is valid
      return true;
    }
  } else {
    // not a date
    return false;
  }
};

export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const roundToTwoDecimals = number => {
  return parseFloat(Math.round(number * 100) / 100).toFixed(2);
};

export const hasNativeWrapper = () => {
  return (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.nativeWrapperAvailableHandler
  );
};

export const callNativeMethod = (methodName, methodParams) => {
  if (hasNativeWrapper() && window.webkit.messageHandlers[methodName]) {
    window.webkit.messageHandlers[methodName].postMessage(
      methodParams ? methodParams : {}
    );
  }
};

export const resetScrollPosition = () => {
  return window.scrollTo(0, 0);
};

export const generateRouteUrl = (street, number, box, zip, city) => {
  const address = encodeURIComponent(
    `${street} ${number} ${box}, ${zip} ${city}`
  );
  return `https://www.google.be/maps/place/${address}`;
};

export const getCleanLocator = locator => {
  return locator.trim().toLowerCase();
};
