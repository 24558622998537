import React, { Component } from "react";

import { Link } from "react-router-dom";

import { IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { withStyles } from "@material-ui/core/styles";

import withLocator from "../components/withLocator";
import withCustomerAuthorization from "../components/withCustomerAuthorization";

import Splash from "../components/Splash";
import BookVisit from "../components/BookVisit";
import Promo from "../components/Promo";
import Copyright from "../components/Copyright";

import withFont from "../components/withFont";

import * as routes from "../constants/routes";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  alreadySignedIn: {
    display: "flex",
    flexDirection: "row",
    zIndex: 1150,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    padding: "5px 0",
    backgroundColor: "#ffc107"
  },
  alreadySignedInText: {
    width: "100%",
    marginLeft: 24
  },
  footer: {
    padding: "20px 10px",
    backgroundColor: "whitesmoke"
  }
});

class BookVisitPage extends Component {
  constructor(props) {
    super(props);

    const { match, auth } = props;

    this.state = { alreadySignedInClosed: false };

    this.handleAlreadySignedInClose = this.handleAlreadySignedInClose.bind(
      this
    );
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleAlreadySignedInClose() {
    this.setState({ alreadySignedInClosed: true });
  }

  render() {
    const { classes, auth, home, assets, availability, isPremium } = this.props;
    const { alreadySignedInClosed } = this.state;

    const hideAds =
      home && home.capabilities && home.capabilities.hideAds === true;

    return (
      <div className={classes.root}>
        {auth && auth.crew && !alreadySignedInClosed && (
          <div className={classes.alreadySignedIn}>
            <div className={classes.alreadySignedInText}>
              Je bent reeds ingelogd waardoor je enkel je eigen pagina kan
              bekijken.{" "}
              <Link to={routes.HOME_VISITS}>
                Naar je overzichtspagina gaan.
              </Link>
            </div>
            <IconButton
              aria-label="close"
              size="small"
              onClick={this.handleAlreadySignedInClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        )}
        <Splash
          home={home}
          assets={assets}
          canMakeVisits={home && home.isLive}
        />
        {home && home.isLive && (
          <BookVisit
            auth={auth}
            home={home}
            availability={availability}
            assets={assets}
            hideAds={hideAds}
          />
        )}
        <div className={classes.footer}>
          <Promo />
          <Copyright color="black" small={true} mobile={false} />
        </div>
      </div>
    );
  }
}

export default withCustomerAuthorization()(
  withLocator()(withFont("name")(withStyles(styles)(BookVisitPage)))
);
