import React, { Component } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0",
    padding: "10px 20px",
    border: "1px solid #eee",
    borderRadius: 2
  },
  disclaimer: {},
  imageAsset: {
    display: "block",
    width: "100%",
    maxWidth: 150,
    margin: 20
  },
  referringUrl: {
    textDecoration: "none"
  },
  callToAction: {
    margin: 20
  }
});

class Ad extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      classes,
      catchPhrase,
      imageAsset,
      callToAction,
      referringUrl
    } = this.props;

    return (
      <div className={classes.container}>
        <Typography className={classes.disclaimer} variant="caption">
          Promotiebericht
        </Typography>
        <img className={classes.imageAsset} src={imageAsset} />
        <Typography className={classes.catchPhrase} variant="h6">
          {catchPhrase}
        </Typography>
        <a className={classes.referringUrl} href={referringUrl} target="_blank">
          <Button
            className={classes.callToAction}
            variant="contained"
            size="large"
            disableElevation
          >
            {callToAction}
          </Button>
        </a>
      </div>
    );
  }
}

export default withStyles(styles)(Ad);
