import React, { Component } from "react";
import List from "@material-ui/core/List";

import { ListItem } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { TimePicker } from "@material-ui/pickers";

import { jsDateToDateString, isValidDate } from "../utils";

import Moment from "moment";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  picker: {
    display: "flex"
  },
  resize: {
    width: 80
  }
});

class SelectTime extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  handleTimeChange(time) {
    const { onChange } = this.props;

    onChange(time);
  }

  render() {
    const { classes, time, label } = this.props;

    return (
      <div className={classes.root}>
        <form className={classes.picker} noValidate>
          <TimePicker
            color="secondary"
            ampm={false}
            value={time}
            label={label === undefined ? "Tijdstip" : label}
            onChange={this.handleTimeChange}
            minutesStep={5}
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.resize
              }
            }}
          />
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(SelectTime);
