import React, { PureComponent } from "react";

import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ShareIcon from "@material-ui/icons/Share";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpIcon from "@material-ui/icons/Help";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";

import { withStyles } from "@material-ui/core/styles";

import AuthContext from "../components/authContext";

import * as routes from "../constants/routes";

import { hasNativeWrapper, callNativeMethod } from "../utils";

import { firebase } from "../firebase";

import LiveControls from "./LiveControls";

const styles = theme => ({
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  link: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)"
  }
});

class SettingsMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleItemClick = this.handleItemClick.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleItemClick() {
    this.handleClose();
  }

  handleSignOut() {
    firebase.auth
      .signOut()
      .then(() => {})
      .catch(error => {
        console.warn(error);

        if (error && error.message) {
          toast.error(error.message);
        } else {
          toast.error("Onbekende fout");
        }
      });
  }

  handleClose = () => {
    this.props.onClose();
  };

  openPrinterModal = () => {
    callNativeMethod("openPrinterModalHandler");
    this.handleItemClick();
  };

  render() {
    const {
      classes,
      anchor,
      isLive,
      isMuted,
      toggleLive,
      toggleMuted,
      isLiveEditable,
      isPremium
    } = this.props;

    const open = Boolean(anchor);

    return (
      <AuthContext.Consumer>
        {auth => (
          <Menu
            anchorEl={anchor}
            open={open}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                //maxHeight: ITEM_HEIGHT * 4.5,
                //width: 200
              }
            }}
          >
            <Link
              to={routes.HOME_BALANCE}
              onClick={this.handleItemClick}
              className={classes.link}
            >
              <MenuItem>
                <ListItemIcon>
                  <LockOpenIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    isPremium ? "Je bent Premium" : "Premium worden"
                  }
                />
              </MenuItem>
            </Link>

            <LiveControls
              isLive={isLive}
              toggleLive={toggleLive}
              isEditable={isLiveEditable}
            />
            <Divider />
            <Link
              to={routes.HOME_VISITS}
              onClick={this.handleItemClick}
              className={classes.link}
            >
              <MenuItem>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Bezoeken" />
              </MenuItem>
            </Link>
            <Link
              to={routes.HOME_AVAILABILITY}
              onClick={this.handleItemClick}
              className={classes.link}
            >
              <MenuItem>
                <ListItemIcon>
                  <DateRangeIcon />
                </ListItemIcon>
                <ListItemText primary="Beschikbaarheid" />
              </MenuItem>
            </Link>
            <Link
              to={routes.HOME_EDIT}
              onClick={this.handleItemClick}
              className={classes.link}
            >
              <MenuItem>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Instellingen" />
              </MenuItem>
            </Link>
            <Link
              to={routes.HOME_PROMO}
              onClick={this.handleItemClick}
              className={classes.link}
            >
              <MenuItem>
                <ListItemIcon>
                  <ShareIcon />
                </ListItemIcon>
                <ListItemText primary="Pagina delen" />
              </MenuItem>
            </Link>
            <Divider />
            <MenuItem
              onClick={() => {
                window.open("https://babybezoek.be/faq", "_blank");
                this.handleItemClick();
              }}
            >
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Hulp nodig?" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open("https://forms.gle/ParG4iW6ofvTMjkf8", "_blank");
                this.handleItemClick();
              }}
            >
              <ListItemIcon>
                <ChatBubbleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="Ons iets melden" />
            </MenuItem>
            <MenuItem onClick={this.handleSignOut}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Afmelden" />
            </MenuItem>
          </Menu>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default withStyles(styles)(SettingsMenu);
