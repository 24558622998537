import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Card, CardHeader, CardContent } from "@material-ui/core";

import withHomeAuthorization from "../components/withHomeAuthorization";

import ShareHome from "../components/ShareHome";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  card: {
    margin: 10,
    width: "100%",
    [theme.breakpoints.up(800)]: {
      maxWidth: 350
    }
  }
});

class HomePromoPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  addItem() {}

  render() {
    const { classes, theme, home } = this.props;

    return (
      <div className={classes.container}>
        <Card className={classes.card}>
          <CardHeader title="Deel je publieke pagina" />
          <CardContent>
            <ShareHome home={home} />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withHomeAuthorization()(
  withStyles(styles, { withTheme: true })(HomePromoPage)
);
