import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { ListItem, ListItemText } from "@material-ui/core";

const styles = theme => ({});

class BookedVisit extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes, time, name } = this.props;

    return (
      <ListItem>
        <ListItemText primary={time} secondary={name} />
      </ListItem>
    );
  }
}

export default withStyles(styles)(BookedVisit);
