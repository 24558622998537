import React, { Component } from "react";
import classNames from "classnames";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon
} from "react-share";

import { withStyles } from "@material-ui/core/styles";

import config from "../env";
import { Typography } from "@material-ui/core";

import GenerateQRCode from "../components/GenerateQRCode";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  description: {
    marginTop: 20
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  link: {
    fontWeight: "bold",
    textDecoration: "none"
  }
});

class ShareHome extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes, home, assets } = this.props;

    const url = `https://${config.hosting.domain}/${home ? home.locator : ""}`;
    const text = `Wij verwelkomen ${
      home ? home.name : ""
    }. Kom je graag eens langs? Laat ons via deze link weten wanneer het je past!`;
    const hashtag = "#babybezoek";

    return (
      <div className={classes.root}>
        <Typography variant="subtitle1">
          Je publieke pagina is nu live op
          <br />
          <a className={classes.link} href={url} target="_blank">
            {url}
          </a>
        </Typography>
        <Typography variant="overline" className={classes.description}>
          Deel ze nu via je favoriete sociale media
        </Typography>
        <div className={classes.buttons}>
          <FacebookShareButton url={url} quote={text} hashtag={hashtag}>
            <FacebookIcon />
          </FacebookShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon />
          </LinkedinShareButton>
          <TwitterShareButton url={url} title={text} hashtag={hashtag}>
            <TwitterIcon />
          </TwitterShareButton>
          <WhatsappShareButton url={url} quote={text}>
            <WhatsappIcon />
          </WhatsappShareButton>
          <EmailShareButton
            url={url}
            subject={`Kom nu op babybezoek bij ${home ? home.name : ""}`}
            body={text}
          >
            <EmailIcon />
          </EmailShareButton>
        </div>
        <Typography variant="overline" className={classes.description}>
          Onderstaande QR code linkt naar je publieke pagina. Gebruik ze bijvoorbeeld op
          het geboortekaartje.
        </Typography>
        <GenerateQRCode home={home} />
      </div>
    );
  }
}

export default withStyles(styles)(ShareHome);
