import React, { Component } from "react";
import List from "@material-ui/core/List";

import { ListItem, FormControlLabel, Checkbox } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { jsDateToDateString, isValidDate } from "../utils";

import Moment from "moment";
import ReactMoment from "react-moment";
import { Typography } from "@material-ui/core";

const DAY_ORDER = [1, 2, 3, 4, 5, 6, 0];
const DAY_NAMES = [
  "Zondag",
  "Maandag",
  "Dinsdag",
  "Woensdag",
  "Donderdag",
  "Vrijdag",
  "Zaterdag"
];

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  days: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  day: {
    width: "auto"
  }
});

class SelectDays extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  handleChange = index => event => {
    const { days, onChange } = this.props;

    days[index] = event.target.checked === true;

    onChange(days);
  };

  render() {
    const { classes, days } = this.props;

    return (
      <div className={classes.root}>
        <List className={classes.stats}>
          <ListItem>
            <Typography variant="overline">Op volgende dagen</Typography>
          </ListItem>
          <div className={classes.days}>
            {DAY_ORDER.map(day => (
              <ListItem key={day} className={classes.day}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={days[day]}
                      onChange={this.handleChange(day)}
                      value={day}
                      color="primary"
                    />
                  }
                  label={DAY_NAMES[day]}
                />
              </ListItem>
            ))}
          </div>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(SelectDays);
