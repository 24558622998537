import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import { DatePicker } from "@material-ui/pickers";

import { jsDateToDateString, isValidDate } from "../utils";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  picker: {
    display: "flex"
  }
});

class SelectDate extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  handleDateChange(date) {
    const { onChange } = this.props;

    if (date) {
      try {
        const newDate = date.startOf("day").toDate();
        if (isValidDate(newDate)) {
          onChange(newDate);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  render() {
    const { classes, date, label } = this.props;

    return (
      <div className={classes.root}>
        <form className={classes.picker} noValidate>
          <DatePicker
            color="secondary"
            label={label === undefined ? "Datum" : label}
            value={jsDateToDateString(date)}
            className={classes.dateField}
            onChange={this.handleDateChange}
            format="dd D MMMM YYYY"
            autoOk={true}
            showTodayButton={true}
            okLabel="OK"
            cancelLabel="Annuleren"
            todayLabel="Vandaag"
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.resize
              }
            }}
          />
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(SelectDate);
