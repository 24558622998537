import React, { Component } from "react";
import classNames from "classnames";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";

import { List, ListSubheader } from "@material-ui/core";

import ReactMoment from "react-moment";

import { withStyles } from "@material-ui/core/styles";

import { store } from "../firebase";

import { jsDateToDateString, getRandomFromArray } from "../utils";

import BookedVisit from "./BookedVisit";
import AvailableVisit from "./AvailableVisit";
import Ad from "./Ad";

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  bar: {
    boxShadow: "none",
    backgroundColor: "white"
  },
  content: {
    display: "flex",
    justifyContent: "center",
    minHeight: "calc(100vh - 56px)",
    [theme.breakpoints.up("600")]: {
      minHeight: "calc(100vh - 64px)"
    }
  },
  list: {
    width: 400
  },
  nameAsText: {
    width: "100%",
    fontSize: 30,
    textAlign: "center",
    color: theme.palette.primary.main
  },
  nameAsImage: {
    width: "100%",
    height: 50,
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center"
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 56px)",
    [theme.breakpoints.up("600")]: {
      height: "calc(100vh - 64px)"
    }
  },
  day: {
    top: 56,
    [theme.breakpoints.up("600")]: {
      top: 64
    },
    backgroundColor: theme.palette.background.paper
  },
  noDays: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 56px)",
    [theme.breakpoints.up("600")]: {
      height: "calc(100vh - 64px)"
    }
  }
});

class BookVisit extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { home } = this.props;

    this.unsubscribeVisits = store.getUpcomingVisits(home.id, (visits) => {
      const visitsAsObject = {};

      visits.forEach((visit) => {
        if (visitsAsObject[visit.date] === undefined) {
          visitsAsObject[visit.date] = {};
        }

        visitsAsObject[visit.date][visit.time] = visit.name;
      });

      this.setState({ visits: visitsAsObject });
    });

    this.unsubscribeAds = store.onGetAds((ads) => {
      const currentAd = getRandomFromArray(ads);
      this.setState({ ads, currentAd });
    });
  }

  componentWillUnmount() {
    if (this.unsubscribeVisits) {
      this.unsubscribeVisits();
      this.unsubscribeVisits = undefined;
    }

    if (this.unsubscribeAds) {
      this.unsubscribeAds();
      this.unsubscribeAds = undefined;
    }
  }

  render() {
    const { classes, auth, home, availability, assets, hideAds } = this.props;
    const { visits, currentAd } = this.state;

    const today = jsDateToDateString(new Date());
    let days;
    if (availability) {
      if (availability.days) {
        days = availability.days.filter((day) => {
          return day.date >= today;
        });
      } else {
        days = [];
      }
    }

    const adIndex = !hideAds && days ? Math.floor(days.length / 2) : -1;

    return (
      <div className={classNames("visits", classes.root)}>
        <AppBar position="sticky" className={classes.bar}>
          <Toolbar>
            {assets && assets.headerName ? (
              <div
                className={classes.nameAsImage}
                style={{ backgroundImage: `url('${assets.headerName.url}')` }}
              ></div>
            ) : (
              <Typography
                variant="h6"
                className={classNames(classes.nameAsText, "apply-font")}
              >
                {home.name}
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <List className={classes.list}>
            {days === undefined ? (
              <div className={classes.spinner}>
                <CircularProgress />
              </div>
            ) : days.length > 0 && visits ? (
              days.map((day, index) => (
                <div key={day.date}>
                  <ListSubheader className={classes.day}>
                    <Typography variant="overline">
                      <ReactMoment locale="nl-be" format="dddd D MMMM YYYY">
                        {day.date}
                      </ReactMoment>
                    </Typography>
                  </ListSubheader>
                  <div>
                    {day.times &&
                      day.times.map((time) => (
                        <div key={time}>
                          {visits &&
                          visits[day.date] &&
                          visits[day.date][time] ? (
                            <div>
                              {home.params &&
                                !home.params.hideVisitsForPublic && (
                                  <BookedVisit
                                    home={home}
                                    date={day.date}
                                    time={time}
                                    name={visits[day.date][time]}
                                  />
                                )}
                            </div>
                          ) : (
                            <AvailableVisit
                              auth={auth}
                              home={home}
                              date={day.date}
                              time={time}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                  {index === adIndex && currentAd && (
                    <Ad
                      catchPhrase={currentAd.catchPhrase}
                      imageAsset={currentAd.imageAsset}
                      callToAction={currentAd.callToAction}
                      referringUrl={currentAd.referringUrl}
                    />
                  )}
                </div>
              ))
            ) : (
              <div className={classes.noDays}>
                <Typography>Geen data beschikbaar.</Typography>
              </div>
            )}
          </List>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BookVisit);
