import React from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography
} from "@material-ui/core";

import ReactMoment from "react-moment";

import { toast } from "react-toastify";

import { store } from "../firebase";

import ConfirmRemoveVisit from "../dialogs/ConfirmRemoveVisit";
import AddVisitToCalendar from "./AddVisitToCalendar";

const styles = theme => ({
  card: {
    margin: 10,
    width: 250
  },
  actions: {
    justifyContent: "center"
  }
});

class Visit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmRemoveVisitOpen: false
    };

    this.handleRemove = this.handleRemove.bind(this);
    this.handleRemoveVisitConfirm = this.handleRemoveVisitConfirm.bind(this);
    this.handleRemoveVisitCancel = this.handleRemoveVisitCancel.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleRemove() {
    this.setState({ confirmRemoveVisitOpen: true });
  }

  handleRemoveVisitConfirm() {
    const { home, id } = this.props;

    this.setState({ confirmRemoveVisitOpen: false });

    const toastId = toast("Bezoek verwijderen...", { autoClose: false });
    store
      .removeVisit(home.id, id)
      .then(() => {
        toast.update(toastId, {
          render: "Bezoek verwijderd",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);
      });
  }

  handleRemoveVisitCancel() {
    this.setState({ confirmRemoveVisitOpen: false });
  }

  render() {
    const { classes, home, id, date, time, name } = this.props;
    const { confirmRemoveVisitOpen } = this.state;

    const showAddVisitToCalendar =
      home && home.capabilities && home.capabilities.showAddVisitToCalendar === true;

    return (
      <div>
        <ConfirmRemoveVisit
          open={confirmRemoveVisitOpen}
          onConfirm={this.handleRemoveVisitConfirm}
          onCancel={this.handleRemoveVisitCancel}
          date={date}
          time={time}
        />
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="overline">
              <ReactMoment locale="nl-be" format="dddd D MMMM YYYY">
                {date}
              </ReactMoment>
              <br />
              {time}
            </Typography>
            <Typography variant="h6">{name}</Typography>
            {showAddVisitToCalendar && (
              <AddVisitToCalendar
                home={home}
                visitId={id}
                caption="Toevoegen aan mijn agenda"
              />
            )}
          </CardContent>
          <CardActions className={classes.actions}>
            <Button size="small" color="primary" onClick={this.handleRemove}>
              Verwijderen
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Visit);
