import React from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import { Typography, TextField } from "@material-ui/core";

import FontPicker from "font-picker-react";

import { toast } from "react-toastify";

import config from "../env";

import { store } from "../firebase";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  fontContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 20
  },
  fontPickerContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10
  },
  preview: {
    borderRadius: 5,
    fontSize: 32,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main
  }
});

class EditFont extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleFontFamilyChange = this.handleFontFamilyChange.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleFontFamilyChange(font) {
    console.info(font);
    const { home } = this.props;

    let fonts = { ...home.fonts };
    if (fonts["name"] === undefined) {
      fonts["name"] = {};
    }
    fonts["name"].family = font.family;

    store
      .setHomeFonts(home.id, fonts)
      .then(() => {})
      .catch(error => {
        toast.error("Fout bij aanpassen van de kleur");
        console.warn(error);
      });
  }

  handleFontSizeChange(size) {
    console.info(size);
  }

  render() {
    const { classes, home } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.fontContainer}>
          <Typography>Kies hier je lettertype</Typography>

          {home && home.fonts && home.fonts.name && (
            <div>
              <p className={classNames(classes.preview, "apply-font")}>
                {home.name}
              </p>
              <div className={classes.fontPickerContainer}>
                <FontPicker
                  apiKey={config.webFonts.apiKey}
                  activeFontFamily={home.fonts.name.family}
                  onChange={this.handleFontFamilyChange}
                  limit={100}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditFont);
