import React, { Component } from "react";
import List from "@material-ui/core/List";

import { ListItem, IconButton, Typography } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import { withStyles } from "@material-ui/core/styles";

import SelectTime from "./SelectTime";

import Moment from "moment";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  delete: {
    marginLeft: -14,
    marginRight: -3
  },
  leftSpacing: {
    width: 31
  }
});

class SelectTimes extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleTimeAdd = this.handleTimeAdd.bind(this);
    this.handleTimeRemove = this.handleTimeRemove.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  handleTimeAdd() {
    const { times, onChange } = this.props;

    times.push(
      Moment()
        .startOf("day")
        .add(15, "hours")
        .toDate()
    );

    onChange(times.sort());
  }

  handleTimeRemove = index => () => {
    const { times, onChange } = this.props;

    times.splice(index, 1);

    onChange(times.sort());
  };

  handleTimeChange = index => time => {
    const { times, onChange } = this.props;

    times[index] = time;

    onChange(times.sort());
  };

  render() {
    const { classes, showHeader, times } = this.props;

    return (
      <div className={classes.root}>
        <List className={classes.stats}>
          {showHeader && (
            <ListItem>
              <Typography variant="overline">Op volgende uren</Typography>
            </ListItem>
          )}
          {times.map((time, index) => (
            <ListItem key={index}>
              {times.length > 1 ? (
                <IconButton
                  className={classes.delete}
                  onClick={this.handleTimeRemove(index)}
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <div className={classes.leftSpacing}></div>
              )}
              <SelectTime
                time={time}
                label={null}
                onChange={this.handleTimeChange(index)}
              />
              <IconButton onClick={this.handleTimeAdd}>
                <AddIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(SelectTimes);
