export default {
  firebase: {
    apiKey: "AIzaSyAS2tHmQtjodFvcepnhcB8zseo_MM77foM",
    authDomain: "visit-io.firebaseapp.com",
    databaseURL: "https://visit-io.firebaseio.com",
    projectId: "visit-io",
    storageBucket: "visit-io.appspot.com",
    messagingSenderId: "511588106894",
    appId: "1:511588106894:web:246f000e1604b69f133fdf",
    measurementId: "G-BCMWBQ8BX7"
  },
  analytics: {
    trackingId: "UA-153499921-1"
  },
  functions: {
    app: "https://us-central1-visit-io.cloudfunctions.net/app"
  },
  stripe: {
    pubApiKey: "pk_live_SAx83Fu1aZAbNAHxSXoFo2B400d7fNTzFd"
  },
  hosting: {
    domain: "app.babybezoek.be"
  },
  bugsnag: {
    apiKey: "6a59b5842249e6ef2263208771f38296",
    releaseStage: "production"
  },
  webFonts: {
    apiKey: "AIzaSyAM7tceptncHPc44R7zHhwOoMF7MGZfiZI"
  }
};
