import React, { Component } from "react";

import { Link } from "react-router-dom";

import LockIcon from "@material-ui/icons/Lock";

import { withStyles } from "@material-ui/core/styles";

import * as routes from "../constants/routes";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  link: {
    display: "inline-block",
    margin: "-2px 5px 0 5px",
    padding: "0 5px",
    verticalAlign: "text-top",
    border: "1px solid #3e2723",
    borderRadius: 5,
    textDecoration: "none",
    backgroundColor: "#ffeb3b",
    color: "#3e2723"
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    marginRight: 5,
    fontSize: 14
  },
  caption: {
    fontSize: 12,
    lineHeight: "20px"
  }
});

class PremiumLabel extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <Link to={routes.HOME_BALANCE} className={classes.link}>
        <div className={classes.container}>
          <LockIcon className={classes.icon} />
          <Typography className={classes.caption} variant="overline">
            Premium
          </Typography>
        </div>
      </Link>
    );
  }
}

export default withStyles(styles)(PremiumLabel);
