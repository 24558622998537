import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Card, CardHeader, CardContent } from "@material-ui/core";

import withHomeAuthorization from "../components/withHomeAuthorization";

import EditAvailability from "../components/EditAvailability";

const styles = theme => ({});

class HomeAvailabilityPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  addItem() {}

  render() {
    const { classes, theme, home, availability, assets } = this.props;

    return <EditAvailability home={home} availability={availability} />;
  }
}

export default withHomeAuthorization()(
  withStyles(styles, { withTheme: true })(HomeAvailabilityPage)
);
