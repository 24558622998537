import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Moment from "moment-timezone";

import { jsDateToDateString } from "../utils";

import SelectBeginAndEndDate from "../components/SelectBeginAndEndDate";
import SelectDays from "../components/SelectDays";
import SelectTimes from "../components/SelectTimes";

const styles = {};

class AddAvailabilityWithRepetition extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      beginDate: Moment()
        .startOf("day")
        .toDate(),
      endDate: Moment()
        .endOf("day")
        .add(7, "days")
        .toDate(),
      days: [false, false, false, false, false, false, false]
    };

    this.handleDatesChange = this.handleDatesChange.bind(this);
    this.handleDaysChange = this.handleDaysChange.bind(this);
    this.handleTimesChange = this.handleTimesChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleDatesChange(dates) {
    const { beginDate, endDate } = dates;
    const { times, onChange } = this.props;

    this.setState({ beginDate, endDate }, () => {
      if (onChange) {
        onChange({ dates: this.getDates(), times });
      }
    });
  }

  handleDaysChange(days) {
    const { onChange, times } = this.props;

    this.setState({ days }, () => {
      if (onChange) {
        onChange({ dates: this.getDates(), times });
      }
    });
  }

  handleTimesChange(times) {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ dates: this.getDates(), times });
    }
  }

  getDates() {
    const { beginDate, endDate, days } = this.state;
    const dates = [];

    let date = beginDate;
    while (date <= endDate) {
      if (days[date.getDay()]) {
        dates.push(jsDateToDateString(date));
      }

      date = Moment(date)
        .add(1, "days")
        .toDate();
    }

    return dates;
  }

  render() {
    const { beginDate, endDate, days } = this.state;
    const { times } = this.props;

    return (
      <div>
        <SelectBeginAndEndDate
          beginDate={beginDate}
          endDate={endDate}
          onChange={this.handleDatesChange}
        />
        <SelectDays days={days} onChange={this.handleDaysChange} />
        <SelectTimes
          times={times}
          onChange={this.handleTimesChange}
          showHeader={true}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AddAvailabilityWithRepetition);
