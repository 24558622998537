import React from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  ListSubheader,
  ListItem
} from "@material-ui/core";

import Moment from "moment-timezone";
import ReactMoment from "react-moment";

import withHomeAuthorization from "../components/withHomeAuthorization";

import BalanceLog from "../components/BalanceLog";
import Transactions from "../components/Transactions";
import ChangeTierWithStripe from "../components/ChangeTierWithStripe";
import RedeemCoupon from "../components/RedeemCoupon";
import PremiumAdvantages from "../components/PremiumAdvantages";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  card: {
    margin: 10,
    width: "100%",
    [theme.breakpoints.up(800)]: {
      maxWidth: 350
    }
  }
});

class HomeBalancePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  addItem() {}

  render() {
    const { classes, home, pricing, balance, isPremium } = this.props;

    let days = -1;
    if (balance && balance.tier && balance.tier.validUntil) {
      days = Moment(balance.tier.validUntil).diff(new Date(), "days");
    }

    return (
      <div className={classes.container}>
        {isPremium ? (
          <Card className={classes.card}>
            <CardHeader title="Je bent premium" />
            <CardContent>
              {days >= 0 && (
                <Typography variant="overline">
                  Je voordelen zijn nog{" "}
                  <strong>{`${days} ${days === 1 ? "dag" : "dagen"}`}</strong>{" "}
                  geldig.
                </Typography>
              )}
              <PremiumAdvantages />
            </CardContent>
          </Card>
        ) : (
          <Card className={classes.card}>
            <CardHeader title="Premium worden" />
            <CardContent>
              <Typography>
                <strong style={{ color: "red" }}>
                  Je kan Babybezoek onbeperkt gratis gebruiken.
                </strong>
                <br />
                <br />
                Wil je graag extra functionaliteit?
                <br />
                Word dan nu Premium gebruiker!
                <br />
                <strong>Je Premium abonnement blijft 90 dagen geldig.</strong>
              </Typography>
              <br />
              <PremiumAdvantages />
              <ChangeTierWithStripe home={home} pricing={pricing} />
              <ListItem />
              <ListSubheader>Ik heb een kortingscode</ListSubheader>
              <RedeemCoupon home={home} />
            </CardContent>
          </Card>
        )}
        <Card className={classes.card}>
          <CardHeader title="Transacties" />
          <CardContent>
            <Transactions home={home} />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withHomeAuthorization()(
  withStyles(styles, { withTheme: true })(HomeBalancePage)
);
