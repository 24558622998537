import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "typeface-roboto";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { ToastContainer } from "react-toastify";

import * as routes from "./constants/routes";
import * as tiers from "./constants/tiers";

import { firebase, store, storage } from "./firebase";

import { Redirect } from "react-router-dom";

import BookVisitPage from "./pages/BookVisit";
import HomeRegisterPage from "./pages/HomeRegister";
import HomeSignInPage from "./pages/HomeSignIn";
import HomeSetupPage from "./pages/HomeSetup";
import HomeVisitsPage from "./pages/HomeVisits";
import HomeAvailabilityPage from "./pages/HomeAvailability";
import HomePromoPage from "./pages/HomePromo";
import HomeEditPage from "./pages/HomeEdit";
import HomeBalancePage from "./pages/HomeBalance";

import CrewWrapper from "./components/CrewWrapper";

import withAuthentication from "./components/withAuthentication";
import withAnalytics from "./components/withAnalytics";
import withBugsnag from "./components/withBugsnag";

const DEFAULT_COLOR_PRIMARY = "#91dfcb";
const DEFAULT_COLOR_SECONDARY = "#f5f5f5";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.setHomeId = this.setHomeId.bind(this);
    this.toggleHomeLive = this.toggleHomeLive.bind(this);
  }

  componentDidMount() {
    firebase.auth.onAuthStateChanged(user => {
      this.setState({ balance: null });

      // Unsubscribe
      if (this.unsubscribeCrew) {
        this.unsubscribeCrew();
        this.unsubscribeCrew = undefined;
      }

      if (user) {
        if (user.isAnonymous) {
          this.clearCrew();
        } else {
          this.unsubscribeCrew = store.onCrew(user.uid, crew => {
            if (crew && crew.homes) {
              const homeIds = Object.keys(crew.homes);
              if (homeIds.length === 1) {
                const homeId = homeIds[0];

                // Set Crew
                this.setCrew(homeId);

                // Set Home
                store
                  .getHomeLocator(homeId)
                  .then(homeLocator => {
                    this.setHomeId(homeId, homeLocator, true);
                  })
                  .catch(error => {
                    console.warn(error);
                  });
              } else {
                this.clearCrew();
              }
            } else {
              this.clearCrew();
            }
          });
        }
      } else {
        this.clearHomeId();
        this.clearCrew();
      }
    });
  }

  clearHomeId() {
    // Unsubscribe
    if (this.unsubscribeHomeInfo) {
      this.unsubscribeHomeInfo();
      this.unsubscribeHomeInfo = undefined;
    }
    if (this.unsubscribeHomeAvailability) {
      this.unsubscribeHomeAvailability();
      this.unsubscribeHomeAvailability = undefined;
    }

    this.clearAssets();

    this.setState({ home: null, availability: null });
  }

  setHomeId(id, locator, isCrew) {
    // Unsubscribe
    if (this.unsubscribeHomeInfo) {
      this.unsubscribeHomeInfo();
      this.unsubscribeHomeInfo = undefined;
    }
    if (this.unsubscribeHomeAvailability) {
      this.unsubscribeHomeAvailability();
      this.unsubscribeHomeAvailability = undefined;
    }

    // Info
    if (id && locator) {
      this.unsubscribeHomeInfo = store.onGetHomeInfo(id, info => {
        if (info) {
          console.info(info);
          const home = {
            id,
            locator,
            ...info
          };
          this.setState({ home });

          // Assets
          if (info.assets) {
            this.refreshAssets(id, info.assets);
          }

          // Availability
          this.unsubscribeHomeAvailability = store.onGetHomeAvailability(
            id,
            availability => {
              this.setState({ availability });
            }
          );
        }
      });
    }
  }

  clearCrew() {
    // Unsubscribe
    if (this.unsubscribePricing) {
      this.unsubscribePricing();
      this.unsubscribePricing = undefined;
    }
    if (this.unsubscribeHomeBalance) {
      this.unsubscribeHomeBalance();
      this.unsubscribeHomeBalance = undefined;
    }

    this.setState({
      balance: null,
      pricing: null
    });
  }

  setCrew(homeId) {
    this.clearCrew();

    this.unsubscribePricing = store.onGetPricing(pricing => {
      this.setState({ pricing });
    });
    if (homeId) {
      this.unsubscribeHomeBalance = store.onGetHomeBalance(homeId, balance => {
        this.setState({ balance });
      });
    }
  }

  clearAssets() {
    this.setState({
      assets: null
    });
  }

  refreshAssets(homeId, assets) {
    this.setState({
      assets: null
    });

    if (homeId && assets) {
      this.loadAsset(homeId, "splashName", assets["splashName"]);
      this.loadAsset(homeId, "splashBackground", assets["splashBackground"]);
      this.loadAsset(homeId, "splashLogo", assets["splashLogo"]);
      this.loadAsset(homeId, "headerName", assets["headerName"]);
    }
  }

  loadAsset(homeId, key, asset) {
    if (asset && asset.name) {
      storage
        .ref("homes")
        .child(homeId)
        .child("assets")
        .child(asset.name)
        .getDownloadURL()
        .then(url => {
          let { assets } = this.state;

          if (assets === null) {
            assets = {};
          }
          assets[key] = {
            url,
            ...asset
          };

          this.setState({ assets });
        })
        .catch(error => {
          console.warn(error);
        });
    }
  }

  toggleHomeLive() {
    const { home } = this.state;

    const isLive = home.isLive === true;
    store
      .setHomeIsLive(home.id, !isLive)
      .then(() => {
        console.info("setHomeIsLive success");
      })
      .catch(error => {
        console.warn("setHomeIsLive error:", error);
      });
  }

  render() {
    const { home, availability, visits, assets, balance, pricing } = this.state;

    const isPremium =
      balance && balance.tier && balance.tier.name === tiers.PREMIUM;

    const theme = createMuiTheme({
      palette: {
        primary: {
          main:
            home && home.colors && home.colors.primary
              ? home.colors.primary
              : DEFAULT_COLOR_PRIMARY
        },
        secondary: {
          main:
            home && home.colors && home.colors.secondary
              ? home.colors.secondary
              : DEFAULT_COLOR_SECONDARY
        }
      }
    });

    const CrewRoutesWithProps = props => {
      return (
        <div>
          <Route
            exact
            path={routes.HOME_VISITS}
            render={params => (
              <HomeVisitsPage home={home} {...params} {...props} />
            )}
          />
        </div>
      );
    };

    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="nl-be">
          <div className="App">
            <ToastContainer />
            <div>
              <Switch>
                <Route
                  exact
                  path={routes.HOME_REGISTER}
                  render={params => <HomeRegisterPage {...params} />}
                />
                <Route
                  exact
                  path={routes.HOME_SIGN_IN}
                  render={params => <HomeSignInPage {...params} />}
                />
                <Route
                  exact
                  path={routes.HOME_SETUP}
                  render={params => (
                    <HomeSetupPage
                      home={home}
                      availability={availability}
                      assets={assets}
                      balance={balance}
                      pricing={pricing}
                      {...params}
                    />
                  )}
                />
                <Route
                  exact
                  path={routes.HOME_ROUTE}
                  render={appParams => (
                    <CrewWrapper
                      home={home}
                      balance={balance}
                      toggleHomeLive={this.toggleHomeLive}
                      isPremium={isPremium}
                    >
                      <CrewRoutesWithProps />
                      <Route
                        exact
                        path={routes.HOME_AVAILABILITY}
                        render={params => (
                          <HomeAvailabilityPage
                            home={home}
                            availability={availability}
                            {...params}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={routes.HOME_EDIT}
                        render={params => (
                          <HomeEditPage
                            home={home}
                            assets={assets}
                            isPremium={isPremium}
                            {...params}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={routes.HOME_BALANCE}
                        render={params => (
                          <HomeBalancePage
                            home={home}
                            pricing={pricing}
                            balance={balance}
                            isPremium={isPremium}
                            {...params}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={routes.HOME_PROMO}
                        render={params => (
                          <HomePromoPage home={home} {...params} />
                        )}
                      />
                    </CrewWrapper>
                  )}
                />
                <Route
                  exact
                  path={routes.PUBLIC_INFO}
                  render={
                    params => <Redirect to={routes.HOME_REGISTER} />
                    /*{
                      window.location.href = "https://babybezoek.be";
                      return null;
                    }*/
                  }
                />
                <Route
                  exact
                  path={routes.PUBLIC_BOOK_VISIT}
                  render={params => (
                    <BookVisitPage
                      setHomeId={this.setHomeId}
                      home={home}
                      assets={assets}
                      availability={availability}
                      {...params}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const AppWithoutRouter = withRouter(
  withBugsnag(withAnalytics(withAuthentication(App)))
);

const AppWithRouter = () => (
  <Router>
    <AppWithoutRouter />
  </Router>
);

export default AppWithRouter;
