import React, { Component } from "react";
import classNames from "classnames";

import { Link, Redirect } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";

import { toast } from "react-toastify";

import AuthContext from "../components/authContext";
import Copyright from "../components/Copyright";

import { auth } from "../firebase";

import { hasNativeWrapper, resetScrollPosition } from "../utils";

import { withStyles } from "@material-ui/core/styles";

import * as routes from "../constants/routes";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "100vw",
    minHeight: "100vh",
    backgroundImage: "url('/assets/background.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover"
  },
  card: {
    position: "relative",
    overflow: "visible",
    zIndex: 10,
    width: 300,
    [theme.breakpoints.down(600)]: {
      width: "100%",
      margin: "10px 0",
      borderRadius: 0,
      boxShadow: "none",
      background: "rgba(255 ,255, 255, 0.9)"
    }
  },
  logo: {
    width: 80,
    height: 80,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "auto 100%",
    backgroundImage: 'url("/assets/logo-white.png")',
    [theme.breakpoints.down(600)]: {
      marginTop: 20
    },
    [theme.breakpoints.up(600)]: {
      marginBottom: 20
    }
  },
  textField: {
    width: "100%;"
  },
  divider: {
    marginTop: 20
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  action: {
    margin: 0
  },
  link: {
    textDecoration: "none"
  },
  copyright: {
    marginTop: 10
    /*[theme.breakpoints.up(600)]: {
      position: "absolute",
      bottom: 10,
      left: 0,
      right: 0
    }*/
  },
  info: {
    marginTop: 5,
    fontSize: 14,
    color: "#888"
  }
});

class HomeRegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      repeatPassword: "",
      validEmail: false,
      validPassword: false,
      isRegistering: false
    };
  }

  handleChange = name => event => {
    let validEmail = this.state.validEmail;
    let validPassword = this.state.validPassword;

    let val = event.target.value;

    if (name === "email") {
      validEmail = val && val !== "";
    } else if (name === "password") {
      validPassword = val && val !== "" && val === this.state.repeatPassword;
    } else if (name === "repeatPassword") {
      validPassword = val && val !== "" && val === this.state.password;
    }

    this.setState(
      {
        [name]: val,
        validEmail: validEmail,
        validPassword: validPassword
      },
      () => {}
    );
  };

  handleRegister = event => {
    event.preventDefault();

    this.setState({ isRegistering: true });
    const toastId = toast("Registreren...", { autoClose: false });
    auth
      .doCreateUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => {
        this.setState({ isRegistering: false });

        toast.update(toastId, {
          render: "Succesvol geregistreerd",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      })
      .catch(error => {
        console.warn(error);

        this.setState({ isRegistering: false });

        if (error && error.message) {
          toast.update(toastId, {
            render: `Fout tijdens het registreren (${error.message})`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        } else {
          toast.update(toastId, {
            render: "Fout tijdens het registreren (onbekende fout)",
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });
        }
      });
    return true;
  };

  render() {
    const { classes } = this.props;
    const {
      email,
      password,
      repeatPassword,
      validEmail,
      validPassword,
      isRegistering
    } = this.state;

    return (
      <AuthContext.Consumer>
        {auth =>
          auth && auth.user && !auth.user.isAnonymous ? (
            <Redirect to={routes.HOME_VISITS} />
          ) : (
            <div className={classes.root}>
              <a
                href="https://babybezoek.be"
                target="blank"
                className={classNames(
                  "relativeOnMobile",
                  classes.logo,
                  hasNativeWrapper() ? classes.centeredLogo : undefined
                )}
              />
              <Card className={classes.card}>
                <CardContent>
                  <List className={classes.client}>
                    <form onSubmit={this.handleRegister}>
                      <ListItem>
                        <TextField
                          required
                          id="email"
                          label="Je e-mailadres"
                          className={classes.textField}
                          value={email}
                          onChange={this.handleChange("email")}
                          onBlur={resetScrollPosition}
                          margin="normal"
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          required
                          id="password"
                          label="Kies een wachtwoord"
                          type="password"
                          className={classes.textField}
                          value={password}
                          onChange={this.handleChange("password")}
                          onBlur={resetScrollPosition}
                          margin="normal"
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          required
                          id="repeatPassword"
                          label="Herhaal wachtwoord"
                          type="password"
                          className={classes.textField}
                          value={repeatPassword}
                          onChange={this.handleChange("repeatPassword")}
                          onBlur={resetScrollPosition}
                          margin="normal"
                        />
                      </ListItem>
                      <Button
                        id="verify-button"
                        type="submit"
                        size="medium"
                        disabled={
                          !validEmail || !validPassword || isRegistering
                        }
                      >
                        Registreren
                      </Button>
                    </form>
                    <Divider className={classes.divider} />
                  </List>
                </CardContent>
                <CardActions className={classes.actions}>
                  <div className={classes.info}>Al een account?</div>
                  <Link
                    to={routes.HOME_SIGN_IN}
                    className={`${classes.action} ${classes.link}`}
                  >
                    <Button size="small">Aanmelden</Button>
                  </Link>
                </CardActions>
              </Card>
              <div className={`relativeOnMobile ${classes.copyright}`}>
                <Copyright color="white" />
              </div>
            </div>
          )
        }
      </AuthContext.Consumer>
    );
  }
}

export default withStyles(styles)(HomeRegisterPage);
