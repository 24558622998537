import React from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import {
  ListItem,
  ListItemText,
  IconButton,
  Typography
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import ReactMoment from "react-moment";

import { toast } from "react-toastify";

import { store } from "../firebase";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  day: {
    /*zIndex: 10,
    position: "sticky",
    top: 56,
    [theme.breakpoints.up("600")]: {
      top: 64
    },*/
    backgroundColor: "white"
  },
  weekendDay: {
    borderLeft: "2px solid rgba(174, 198, 207, 1)"
  },
  weekendTime: {
    borderLeft: "2px solid rgba(174, 198, 207, 0.6)"
  }
});

class AvailabilityForDay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDayRemove = this.handleDayRemove.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleDayRemove() {
    const { home, date } = this.props;

    const toastId = toast("Dag verwijderen...", { autoClose: false });
    store
      .removeAvailabilityForDay(home.id, date)
      .then(() => {
        toast.update(toastId, {
          render: "Dag verwijderd",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);
      });
  }

  handleTimeRemove = time => () => {
    const { home, date } = this.props;

    const toastId = toast("Tijdstip verwijderen...", { autoClose: false });
    store
      .removeAvailabilityForDayAtTime(home.id, date, time)
      .then(() => {
        toast.update(toastId, {
          render: "Tijdstip verwijderd",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);
      });
  };

  isWeekend(date) {
    const jsDate = new Date(date);
    const day = jsDate.getDay();
    return day === 0 || day === 6;
  }

  render() {
    const { classes, date, times } = this.props;

    return (
      <div>
        <ListItem
          className={classNames(
            classes.day,
            this.isWeekend(date) ? classes.weekendDay : undefined
          )}
        >
          <ListItemText
            primary={
              <Typography variant="overline">
                <ReactMoment locale="nl-be" format="dddd D MMMM YYYY">
                  {date}
                </ReactMoment>
              </Typography>
            }
          />
          <IconButton onClick={this.handleDayRemove}>
            <DeleteIcon />
          </IconButton>
        </ListItem>
        {times.map(time => (
          <ListItem
            key={time}
            className={this.isWeekend(date) ? classes.weekendTime : undefined}
          >
            <ListItemText primary={time}></ListItemText>
            <IconButton onClick={this.handleTimeRemove(time)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AvailabilityForDay);
