import React, { Component } from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  logo: {
    height: 40,
    margin: "5px 0 -5px 0"
  },
  links: {
    fontSize: 12,
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.9)"
  },
  link: {
    display: "inline-block",
    margin: 10,
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.8)"
  },
  smallLogo: { marginRight: 5 },
  smallImg: {
    height: 20
  },
  smallLinks: {
    display: "inline-block"
  },
  smallLink: {
    fontSize: 10
  }
});

class Copyright extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { classes, color, small, mobile } = this.props;

    return (
      <div style={mobile === false ? { minWidth: 320 } : {}}>
        <a
          href="https://theperfecttwist.com"
          target="_blank"
          className={small ? classes.smallLogo : ""}
        >
          <img
            className={classNames(classes.logo, small ? classes.smallImg : "")}
            src="/assets/theperfecttwist-small.png"
          />
        </a>
        <div
          className={classNames(classes.links, small ? classes.smallLinks : "")}
        >
          <a
            className={classNames(
              classes.link,
              mobile === false ? "" : "blockOnMobile",
              small ? classes.smallLink : ""
            )}
            style={color ? { color } : {}}
            href="https://babybezoek.be"
            target="_blank"
          >
            Meer Info
          </a>
          <span className="hideOnMobile" style={color ? { color } : {}}>
            &bull;
          </span>
          <a
            className={classNames(
              classes.link,
              mobile === false ? "" : "blockOnMobile",
              small ? classes.smallLink : ""
            )}
            style={color ? { color } : {}}
            href="https://babybezoek.be/algemene-voorwaarden"
            target="_blank"
          >
            Algemene Voorwaarden
          </a>
          <span className="hideOnMobile" style={color ? { color } : {}}>
            &bull;
          </span>
          <a
            className={classNames(
              classes.link,
              mobile === false ? "" : "blockOnMobile",
              small ? classes.smallLink : ""
            )}
            style={color ? { color } : {}}
            href="https://babybezoek.be/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Copyright);
