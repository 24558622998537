import React from "react";

import AuthContext from "./authContext";

import { firebase, store } from "../firebase";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        ready: false
      };
    }

    componentDidMount() {
      this.refreshOnAuthState();
    }

    componentWillUnmount() {}

    refreshOnAuthState() {
      //const { setShopId } = this.props;

      this.unsubscribeOnAuthStateChanged = firebase.auth.onAuthStateChanged(
        user => {
          console.log("authStateRefresh:", user);
          // Unsubscribe
          if (this.unsubscribeCrew) {
            this.unsubscribeCrew();
            this.unsubscribeCrew = undefined;
          }

          if (user) {
            if (user.isAnonymous) {
              this.setState(() => ({
                ready: true,
                user,
                crew: null
              }));
            } else {
              this.unsubscribeCrew = store.onCrew(user.uid, crew => {
                if (crew) {
                  this.setState(() => ({
                    ready: true,
                    user,
                    crew
                  }));
                } else {
                  this.setState(() => ({
                    ready: true,
                    user,
                    crew: null
                  }));
                }
              });
            }
          } else {
            this.setState(() => ({
              ready: true,
              user: null,
              crew: null
            }));
          }
        }
      );
    }

    render() {
      const { ready, user, crew } = this.state;

      const auth = {
        user,
        crew
      };

      return (
        <AuthContext.Provider value={auth}>
          {ready && <Component />}
        </AuthContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
