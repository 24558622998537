import React from "react";

import { withRouter } from "react-router-dom";

import { Redirect } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core/styles";

import { store } from "../firebase";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "100vh"
  },
  loading: {
    position: "absolute",
    zIndex: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
});

const withLocator = authCondition => Component => {
  class WithLocator extends React.Component {
    constructor(props) {
      super(props);

      const hasLocator =
        props.match && props.match.params && props.match.params.locator;
      const { home } = props;

      this.state = {};

      if (hasLocator) {
        const locator = props.match.params.locator;

        if (home && home.locator === locator) {
          console.warn("home already set");
        } else {
          this.state.locator = locator;
          this.state.loading = true;
        }

        if (localStorage) {
          const lsLocator = localStorage.getItem("locator");
          if (lsLocator !== locator) {
            localStorage.clear();
          }
          localStorage.setItem("locator", locator);
        }
      }
    }

    componentDidMount() {
      const { setHomeId } = this.props;
      const { locator } = this.state;

      if (locator) {
        store.getHomeId(locator).then(
          homeId => {
            setHomeId(homeId, locator);
            this.setState({ locator: undefined });
          },
          error => {
            this.setState({
              loading: false,
              error: `Niet gevonden: ${error}`
            });
          }
        );
      }
    }

    render() {
      const { home, classes, match, ...other } = this.props;
      const { locator, loading, error } = this.state;
      const { name } = match.params;

      if (locator === undefined && home && home.id) {
        return (
          <Component home={home} locator={locator} match={match} {...other} />
        );
      }

      return (
        <div className={classes.root}>
          {loading ? (
            <div className={classes.loading}>
              <CircularProgress className={classes.spinner} />
            </div>
          ) : (
            <div className={classes.content}>{error && <div>{error}</div>}</div>
          )}
        </div>
      );
    }
  }

  return withRouter(withStyles(styles)(WithLocator));
};

export default withLocator;
