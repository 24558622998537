import React from "react";
import { withStyles } from "@material-ui/core/styles";

import SetupWizard from "../components/SetupWizard";

const styles = theme => ({});

class HomeSetupPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const {
      classes,
      theme,
      home,
      availability,
      assets,
      balance,
      pricing
    } = this.props;

    return (
      <div>
        <SetupWizard
          home={home}
          availability={availability}
          assets={assets}
          balance={balance}
          pricing={pricing}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HomeSetupPage);
