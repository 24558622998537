import React from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fab
} from "@material-ui/core";

import LockOpenIcon from "@material-ui/icons/LockOpen";

import { toast } from "react-toastify";

import { store } from "../firebase";
import { redirectToCheckout } from "../stripe";
import { roundToTwoDecimals } from "../utils";

import withAuthorization from "./withAuthorization";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  center: { textAlign: "center" }
});

class ChangeTierWithStripe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsDisabled: false
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handlePurchaseClick(pricingId) {
    const { auth, home } = this.props;

    this.setState({ optionsDisabled: true });

    const toastId = toast("Doorverwijzen naar de betaalpagina...", {
      autoClose: false
    });
    store
      .getStripeSessionId(auth.user.uid, home.id, pricingId, data => {
        console.log("progress", data);
      })
      .then(data => {
        if (data && data.sessionId) {
          redirectToCheckout({
            sessionId: data.sessionId
          })
            .then(function(result) {
              console.info(result);

              if (result && result.error && result.error.message) {
                toast.update(toastId, {
                  render: `Er ging iets fout (${result.error.message})`,
                  type: toast.TYPE.ERROR,
                  autoClose: 5000
                });
              } /* else {
                toast.update(toastId, {
                  render: `Er ging iets fout (onbekende fout)`,
                  type: toast.TYPE.ERROR,
                  autoClose: 5000
                });
              }*/

              this.setState({ optionsDisabled: false });
            })
            .catch(error => {
              toast.update(toastId, {
                render: `Er ging iets fout (${
                  error ? error.toString() : error
                })`,
                type: toast.TYPE.ERROR,
                autoClose: 5000
              });

              this.setState({ optionsDisabled: false });
            });
        } else {
          toast.update(toastId, {
            render: `Er ging iets fout (sessionId is not defined)`,
            type: toast.TYPE.ERROR,
            autoClose: 5000
          });

          this.setState({ optionsDisabled: false });
        }
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);

        this.setState({ optionsDisabled: false });
      });
  }

  render() {
    const { classes, theme, home, balance, pricing } = this.props;
    const { optionsDisabled } = this.state;

    return (
      <List>
        {pricing &&
          pricing.map(option => (
            <div key={option.id}>
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="checkout"
                className={classes.margin}
                onClick={() => {
                  this.handlePurchaseClick(option.id);
                }}
                disabled={optionsDisabled}
              >
                <LockOpenIcon className={classes.extendedIcon} />
                {`${option.label} worden voor ${option.tierValidity} dagen (€${option.totalPrice})`}
              </Fab>
              <ListItem>
                <ListItemText primary="" className={classes.center} />
              </ListItem>
            </div>
          ))}
      </List>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(ChangeTierWithStripe)
);
