import React from "react";

import { withStyles } from "@material-ui/core/styles";

import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField
} from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import config from "../env";

import { store } from "../firebase";

import { makeCancelable } from "../utils";

import withAuthorization from "./withAuthorization";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  list: { width: "100%" },
  website: {
    marginBottom: 0,
    marginRight: 6
  },
  description: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: "0.75rem",
    lineHeight: "1em",
    letterSpacing: "0.03333em",
    color: "rgba(0, 0, 0, 0.54)"
  },
  link: {
    marginTop: 0,
    marginBottom: 10
  }
});

class CreateHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: ""
    };

    this.handleCreate = this.handleCreate.bind(this);
  }

  componentDidMount() {
    const { setCreate } = this.props;
    setCreate(this.handleCreate);
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleChange = key => event => {
    const val = event.target.value;

    let newState = {};
    newState[key] = val;

    switch (key) {
      case "name":
        break;
    }

    this.setState(newState);
  };

  handleCreate() {
    const { auth } = this.props;
    const { name } = this.state;

    return new Promise((resolve, reject) => {
      this.setState({ fieldsDisabled: true });
      store
        .createHome(auth.user.uid, name, data => {
          console.log("progress", data);
        })
        .then(data => {
          this.setState({ fieldsDisabled: false });
          resolve(data);
        })
        .catch(error => {
          this.setState({ fieldsDisabled: false });
          reject(error.message);
        });
    });
  }

  render() {
    const { classes, assets } = this.props;
    const { name, fieldsDisabled } = this.state;

    return (
      <div className={classes.root}>
        <List className={classes.list}>
          <ListItem>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Naam"
              helperText="De naam van je kindje"
              fullWidth
              disabled={fieldsDisabled}
              value={name}
              onChange={this.handleChange("name")}
            />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withAuthorization()(
  withStyles(styles, { withTheme: true })(CreateHome)
);
