import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { ListItem, ListItemText, Button } from "@material-ui/core";

import Moment from "moment";

import BookVisitDialog from "../dialogs/BookVisit";

const styles = theme => ({
  root: {
    flexGrow: 1
  }
});

class AvailableVisit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookVisitOpen: false
    };

    this.handleBookVisitOpen = this.handleBookVisitOpen.bind(this);
    this.handleBookVisitClose = this.handleBookVisitClose.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleBookVisitOpen() {
    this.setState({ bookVisitOpen: true });
  }

  handleBookVisitClose() {
    this.setState({ bookVisitOpen: false });
  }

  canBeBooked() {
    const { home, date } = this.props;

    return (
      !Moment().isSame(date, "day") &&
      Moment(date).diff(Moment(), "days") >=
        (home && home.params && home.params.minDaysInAdvance
          ? home.params.minDaysInAdvance
          : 0)
    );
  }

  render() {
    const { classes, auth, home, date, time } = this.props;
    const { bookVisitOpen } = this.state;

    return (
      <div className={classes.root}>
        <BookVisitDialog
          open={bookVisitOpen}
          onClose={this.handleBookVisitClose}
          auth={auth}
          home={home}
          date={date}
          time={time}
        />
        <ListItem>
          <ListItemText primary={time} />
          {this.canBeBooked() && (
            <Button
              variant="contained"
              className={classes.button}
              onClick={this.handleBookVisitOpen}
            >
              Ik kom!
            </Button>
          )}
        </ListItem>
      </div>
    );
  }
}

export default withStyles(styles)(AvailableVisit);
