import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, IconButton } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import ReactMoment from "react-moment";

import InfiniteScroll from "react-infinite-scroll-component";

import { toast } from "react-toastify";

import { store } from "../firebase";

import ConfirmRemoveVisitDialog from "../dialogs/ConfirmRemoveVisit";

const styles = theme => ({
  visitList: {
    overflowY: "scroll",
    height: "100%"
  }
});

class AllVisits extends React.Component {
  constructor(props) {
    super(props);

    this.state = { confirmRemoveVisitOpen: false };

    this.handleRemoveVisitConfirm = this.handleRemoveVisitConfirm.bind(this);
    this.handleRemoveVisitCancel = this.handleRemoveVisitCancel.bind(this);
  }

  handleRemoveVisit = (id, visit) => () => {
    this.setState({
      confirmRemoveVisitOpen: true,
      visitId: id,
      visitDate: visit.date,
      visitTime: visit.time
    });
  };

  handleRemoveVisitConfirm() {
    const { home } = this.props;
    const { visitId } = this.state;

    this.setState({
      confirmRemoveVisitOpen: false,
      visitId: null,
      visitDate: null,
      visitTime: null
    });

    const toastId = toast("Bezoek verwijderen...", { autoClose: false });
    store
      .removeVisit(home.id, visitId)
      .then(() => {
        toast.update(toastId, {
          render: "Bezoek verwijderd",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);
      });
  }

  handleRemoveVisitCancel() {
    this.setState({ confirmRemoveVisitOpen: false, visitId: null });
  }

  render() {
    const { classes, allVisits, fetchMoreVisits, hasMoreVisits } = this.props;
    const { confirmRemoveVisitOpen, visitDate, visitTime } = this.state;

    return (
      <List id="allVisits" className={classes.visitList}>
        <ConfirmRemoveVisitDialog
          open={confirmRemoveVisitOpen}
          onConfirm={this.handleRemoveVisitConfirm}
          onCancel={this.handleRemoveVisitCancel}
          date={visitDate}
          time={visitTime}
        />
        {allVisits && (
          <InfiniteScroll
            scrollableTarget="allVisits"
            dataLength={allVisits.length}
            next={fetchMoreVisits}
            hasMore={hasMoreVisits}
            loader={<h4>Laden...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! Alle bezoeken worden getoond 🎉</b>
              </p>
            }
          >
            {allVisits.map((visit, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={visit.name}
                  secondary={
                    <span>
                      <ReactMoment locale="nl-be" format="dd D/M">
                        {visit.date}
                      </ReactMoment>{" "}
                      om {visit.time}
                    </span>
                  }
                />
                <IconButton onClick={this.handleRemoveVisit(visit.id, visit)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </InfiniteScroll>
        )}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AllVisits);
