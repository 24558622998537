import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import withMobileDialog from "@material-ui/core/withMobileDialog";

import { withStyles } from "@material-ui/core/styles";

import Moment from "moment";

import { toast } from "react-toastify";

import { store } from "../firebase";

import { jsDateToDateString, jsDateToTimeString } from "../utils";

import SelectDateAndTime from "../components/SelectDateAndTime";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  textField: {
    width: "100%"
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AddVisitDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: Moment()
        .startOf("day")
        .toDate(),
      time: Moment()
        .startOf("day")
        .add(14, "hours")
        .toDate(),
      name: ""
    };

    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleNameChange(event) {
    const name = event.target.value;

    this.setState({ name });
  }

  handleClose() {
    const { onClose } = this.props;

    onClose();
  }

  handleDateTimeChange(dateAndTime) {
    const { date, time } = dateAndTime;
    this.setState({ date, time });
  }

  handleAdd() {
    const { auth, home } = this.props;
    const { date, time, name } = this.state;

    if (
      auth === undefined ||
      auth.user === undefined ||
      auth.user.uid === undefined
    ) {
      return toast.error("Fout: geen geldige gebruiker gevonden");
    }
    if (name === undefined || name === "") {
      return toast.error(`Fout: naam niet ingevuld`);
    }

    const toastId = toast("Bezoek bewaren...", { autoClose: false });
    store
      .addVisit(
        auth.user.uid,
        home.id,
        jsDateToDateString(date),
        jsDateToTimeString(time),
        name
      )
      .then(() => {
        toast.update(toastId, {
          render: "Bezoek bewaard",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });

        this.setState({
          date: Moment()
            .startOf("day")
            .toDate(),
          time: Moment()
            .startOf("day")
            .add(14, "hours")
            .toDate(),
          name: ""
        });

        this.handleClose();
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);
      });
  }

  handleCancel() {
    const { onClose } = this.props;

    onClose();
  }

  render() {
    const { classes, open } = this.props;
    const { date, time, name } = this.state;

    return (
      <Dialog open={open} onClose={this.handleClose}>
        <DialogTitle>Bezoek toevoegen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vul hieronder de datum, het uur en de naam in en klik vervolgens op
            'Toevoegen' om het bezoek te bevestigen.
          </DialogContentText>
          <SelectDateAndTime
            date={date}
            time={time}
            onChange={this.handleDateTimeChange}
          />
          <TextField
            className={classes.textField}
            label="Naam"
            margin="normal"
            variant="outlined"
            value={name}
            onChange={this.handleNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.handleCancel} color="primary">
            Annuleren
          </Button>
          <Button
            onClick={this.handleAdd}
            color="primary"
            autoFocus
            disabled={name.length === 0}
          >
            Toevoegen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(AddVisitDialog));
