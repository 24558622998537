import React from "react";
import classNames from "classnames";

import { withRouter } from "react-router-dom";

import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SettingsIcon from "@material-ui/icons/Settings";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";

import { store } from "../firebase";

import { findInArrayById, hasNativeWrapper } from "../utils";

import * as routes from "../constants/routes";

import withHomeAuthorization from "./withHomeAuthorization";
import withFont from "../components/withFont";

import SettingsMenu from "./SettingsMenu";
import AddVisitDialog from "../dialogs/AddVisit";
import AllVisits from "./AllVisits";

const drawerWidth = 240;
const visitsInitialBatchSize = 20;
const visitsAdditionalBatchSize = 10;

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  headerSmallSpacer: {
    width: 10
  },
  headerLargeSpacer: {
    width: 0
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  link: {
    textDecoration: "none"
  },
  switchLabel: {
    marginTop: 4,
    color: "white"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  headerItem: {
    color: "white"
  },
  title: {}
});

class CrewWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addVisitDialogOpen: false,
      allVisitsMaxCount: visitsInitialBatchSize
    };

    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleAddVisit = this.handleAddVisit.bind(this);
    this.handleAddVisitDialogClose = this.handleAddVisitDialogClose.bind(this);
    this.handleFetchMoreVisits = this.handleFetchMoreVisits.bind(this);
  }

  componentDidMount() {
    this.refreshAllVisits();
    this.refreshUpcomingVisits();
  }

  componentWillUnmount() {
    if (this.unsubscribeAllVisits) {
      this.unsubscribeAllVisits();
    }
    if (this.unsubscribeUpcomingVisits) {
      this.unsubscribeUpcomingVisits();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { home } = this.props;
    const { allVisitsMaxCount } = this.state;

    if (home && home.id) {
      if (
        prevProps.home === undefined ||
        prevProps.home === null ||
        prevProps.home.id !== home.id
      ) {
        this.refreshAllVisits();
        this.refreshUpcomingVisits();
      } else if (allVisitsMaxCount > prevState.allVisitsMaxCount) {
        this.refreshAllVisits();
      }
    }
  }

  refreshAllVisits() {
    const { home } = this.props;
    const { allVisitsMaxCount } = this.state;

    if (this.unsubscribeAllVisits) {
      this.unsubscribeAllVisits();
    }

    if (home && home.id) {
      this.unsubscribeAllVisits = store.getAllVisits(
        home.id,
        allVisitsMaxCount,
        visits => {
          this.setState({
            allVisits: visits
          });
        }
      );
    }
  }

  refreshUpcomingVisits() {
    const { home } = this.props;

    if (this.unsubscribeUpcomingVisits) {
      this.unsubscribeUpcomingVisits();
    }

    if (home && home.id) {
      this.unsubscribeUpcomingVisits = store.getUpcomingVisits(
        home.id,
        visits => {
          this.setState({ upcomingVisits: visits });
        }
      );
    }
  }

  handleAddVisit(event) {
    this.setState({ addVisitDialogOpen: true });
  }

  handleAddVisitDialogClose() {
    this.setState({ addVisitDialogOpen: false });
  }

  handleFetchMoreVisits() {
    const { allVisitsMaxCount } = this.state;
    this.setState({
      allVisitsMaxCount: allVisitsMaxCount + visitsAdditionalBatchSize
    });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  toggleMenu = event => {
    const { menuAnchor } = this.state;

    if (menuAnchor) {
      this.setState({ menuAnchor: undefined });
    } else {
      this.setState({ menuAnchor: event.currentTarget });
    }
  };

  handleMenuClose() {
    this.setState({ menuAnchor: undefined });
  }

  render() {
    const {
      classes,
      theme,
      auth,
      location,
      home,
      balance,
      toggleHomeLive,
      isPremium
    } = this.props;
    const {
      open,
      menuAnchor,
      addVisitDialogOpen,
      allVisits,
      allVisitsMaxCount,
      upcomingVisits
    } = this.state;

    const children = React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        allVisits,
        upcomingVisits
      });
    });

    const route = location ? location.pathname : undefined;

    return (
      <div className={classes.root}>
        <AddVisitDialog
          open={addVisitDialogOpen}
          onClose={this.handleAddVisitDialogClose}
          auth={auth}
          home={home}
        />
        <CssBaseline />
        <SettingsMenu
          anchor={menuAnchor}
          onClose={this.handleMenuClose}
          isLive={home && home.isLive}
          toggleLive={toggleHomeLive}
          isLiveEditable={true}
          isPremium={isPremium}
        />
        <AppBar
          position="fixed"
          className={classNames(
            classes.appBar,
            {
              [classes.appBarShift]: open
            },
            hasNativeWrapper() ? "App-header-ios" : undefined
          )}
        >
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                classes.headerItem,
                open && classes.hide
              )}
            >
              <ViewHeadlineIcon />
            </IconButton>
            <Button
              onClick={this.handleAddVisit}
              aria-label="Add Visit"
              className={classes.headerItem}
              startIcon={<AddToPhotosIcon />}
            >
              <span className="hideOnMobileAndTablet">
                Zelf bezoek toevoegen
              </span>
            </Button>
            <div
              className={classNames(
                classes.headerLargeSpacer,
                "hideOnMobileAndTablet"
              )}
            ></div>
            <Link
              to={routes.HOME_VISITS}
              className={classNames(classes.grow, classes.link)}
            >
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                className={classNames(
                  classes.headerItem,
                  classes.title,
                  "apply-font"
                )}
              >
                {home && home.name ? home.name : "Babybezoek"}
              </Typography>
            </Link>
            <div
              className={classNames(classes.headerSmallSpacer, "hideOnDesktop")}
            ></div>
            {upcomingVisits && (
              <Link to={routes.HOME_VISITS} className={classes.link}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.button}
                >
                  <CalendarTodayIcon
                    className={classNames(classes.leftIcon, classes.iconSmall)}
                  />
                  {upcomingVisits.length}
                </Button>
              </Link>
            )}
            <Link
              to={routes.HOME_BALANCE}
              className={classNames(classes.link, "hideOnMobileAndTablet")}
            >
              <Button
                variant="contained"
                size="small"
                color="secondary"
                className={classes.button}
              >
                <LockOpenIcon
                  className={classNames(classes.leftIcon, classes.iconSmall)}
                />
                {isPremium ? "Je bent premium" : "Premium worden"}
              </Button>
            </Link>
            <IconButton
              onClick={this.toggleMenu}
              aria-label="Instellingen"
              className={classes.headerItem}
            >
              <SettingsIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div
            className={classNames(
              classes.drawerHeader,
              hasNativeWrapper() ? "App-drawer-header-ios" : undefined
            )}
          >
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <AllVisits
            home={home}
            allVisits={allVisits}
            fetchMoreVisits={this.handleFetchMoreVisits}
            hasMoreVisits={
              allVisits === undefined || allVisitsMaxCount <= allVisits.length
            }
          />
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: open
          })}
        >
          <div className={classes.drawerHeader} />
          <div>{children}</div>
        </main>
      </div>
    );
  }
}
export default withHomeAuthorization()(
  withFont("name")(
    withRouter(withStyles(styles, { withTheme: true })(CrewWrapper))
  )
);
