import React from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fab
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import AddAvailabilityDialog from "../dialogs/AddAvailability";
import AvailabilityForDay from "./AvailabilityForDay";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center"
  },
  list: {
    width: 400,
    paddingBottom: 100
  },
  add: {
    zIndex: 20,
    position: "fixed",
    bottom: 80,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center"
  }
});

class EditAvailability extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addAvailabilityDialogOpen: false
    };

    this.handleAddAvailability = this.handleAddAvailability.bind(this);
    this.handleAddAvailabilityDialogClose = this.handleAddAvailabilityDialogClose.bind(
      this
    );
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleAddAvailability(event) {
    this.setState({ addAvailabilityDialogOpen: true });
  }

  handleAddAvailabilityDialogClose() {
    this.setState({ addAvailabilityDialogOpen: false });
  }

  render() {
    const { classes, home, availability } = this.props;
    const { addAvailabilityDialogOpen } = this.state;

    return (
      <div className={classes.container}>
        <AddAvailabilityDialog
          open={addAvailabilityDialogOpen}
          onClose={this.handleAddAvailabilityDialogClose}
          home={home}
        />
        <List className={classes.list}>
          {availability && availability.days && availability.days.length > 0 ? (
            <div>
              {availability.days.map(day => (
                <AvailabilityForDay
                  key={day.date}
                  home={home}
                  date={day.date}
                  times={day.times}
                />
              ))}
              <div className={classes.add}>
                <Fab
                  color="primary"
                  variant="extended"
                  onClick={this.handleAddAvailability}
                >
                  <AddIcon />
                  Beschikbaarheid toevoegen
                </Fab>
              </div>
            </div>
          ) : (
            <ListItem button onClick={this.handleAddAvailability}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Beschikbaarheid toevoegen" />
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditAvailability);
