import { store, serverTimestamp, deleteField } from "./firebase";

import Moment from "moment";

import { getCleanLocator } from "../utils";

export const getCrew = userId => {
  return new Promise((resolve, reject) => {
    return store
      .collection("crew")
      .doc(userId)
      .get()
      .then(doc => {
        const crew = doc.data();

        resolve(crew);
      });
  });
};

export const onCrew = (userId, cb) => {
  return store
    .collection("crew")
    .doc(userId)
    .onSnapshot(doc => {
      cb(doc.data());
    });
};

export const isLocatorAvailable = locator => {
  return new Promise((resolve, reject) => {
    if (locator === undefined || locator === null || locator === "") {
      return reject("locator is not defined");
    }
    if (locator.length < 5) {
      return reject("locator length < 5");
    }
    if (locator.length > 15) {
      return reject("locator length > 15");
    }

    const cleanLocator = getCleanLocator(locator);

    return store
      .collection("search")
      .where("cleanLocator", "==", cleanLocator)
      .get()
      .then(docs => {
        resolve(docs.size === 0);
      })
      .catch(reject);
  });
};

export const createHome = (userId, name, onProgress) => {
  return putTask(userId, "home", "create", { name }, onProgress);
};

export const getStripeSessionId = (userId, homeId, pricingId, onProgress) => {
  return putTask(
    userId,
    "stripe",
    "checkout",
    { homeId, pricingId },
    onProgress
  );
};

export const redeemCoupon = (userId, homeId, code, onProgress) => {
  return putTask(userId, "coupon", "redeem", { homeId, code }, onProgress);
};

export const getHomeId = locator => {
  return new Promise((resolve, reject) => {
    if (locator === undefined || locator === null || locator === "") {
      return reject("locator is not defined");
    }

    const cleanLocator = getCleanLocator(locator);

    return store
      .collection("search")
      .where("cleanLocator", "==", cleanLocator)
      .get()
      .then(docs => {
        let homeId;

        if (docs.size === 1) {
          docs.forEach(doc => {
            homeId = doc.id;
          });
        } else if (docs.size === 0) {
          return reject("no homeId found");
        } else {
          return reject("found more then one homeId");
        }

        if (homeId) {
          return resolve(homeId);
        } else {
          return reject("no homeId found");
        }
      })
      .catch(reject);
  });
};

export const getHomeLocator = id => {
  return new Promise((resolve, reject) => {
    if (id === undefined || id === null || id === "") {
      return reject("id is not defined");
    }

    return store
      .collection("search")
      .doc(id)
      .get()
      .then(doc => {
        const data = doc.data();

        if (data && data.locator) {
          resolve(data.locator);
        } else {
          reject("no locator found");
        }
      })
      .catch(reject);
  });
};

export const onGetPricing = cb => {
  return store
    .collection("pricing")
    .orderBy("totalPrice", "asc")
    .onSnapshot(querySnapshot => {
      let pricing = [];
      querySnapshot.forEach(doc => {
        const id = doc.id;
        const data = doc.data();

        pricing.push({
          id,
          ...data
        });
      });
      cb(pricing);
    });
};

export const onGetAds = cb => {
  return store
    .collection("ads")
    .where("isActive", "==", true)
    .onSnapshot(querySnapshot => {
      let ads = [];
      querySnapshot.forEach(doc => {
        const id = doc.id;
        const data = doc.data();

        ads.push({
          id,
          ...data
        });
      });
      cb(ads);
    });
};

export const onGetConf = (key, cb) => {
  return store
    .collection("conf")
    .doc(key)
    .onSnapshot(doc => {
      cb(doc.data());
    });
};

export const onGetPrinters = cb => {
  return store.collection("printers").onSnapshot(querySnapshot => {
    let printers = [];
    querySnapshot.forEach(doc => {
      const id = doc.id;
      const data = doc.data();

      printers.push({
        id,
        ...data
      });
    });
    cb(printers);
  });
};

export const onGetLocators = cb => {
  return store.collection("search").onSnapshot(querySnapshot => {
    let locators = [];
    querySnapshot.forEach(doc => {
      const id = doc.id;
      const data = doc.data();

      locators.push({
        id,
        ...data
      });
    });
    cb(locators);
  });
};

export const onGetHomeInfo = (homeId, cb) => {
  return store
    .collection("homes")
    .doc(homeId)
    .onSnapshot(doc => {
      cb(doc.data());
    });
};

export const onGetHomeAvailability = (homeId, cb) => {
  return getDefaultHomeAvailabilityRef(homeId).onSnapshot(snapshot => {
    const availability = snapshot.data();

    if (availability && availability.days) {
      const sortedDays = availability.days.sort((dayA, dayB) => {
        if (dayA.date < dayB.date) {
          return -1;
        }
        if (dayA.date > dayB.date) {
          return 1;
        }
        return 0;
      });

      cb({ days: sortedDays });
    } else {
      cb({});
    }
  });
};

export const onGetHomeTransactions = (homeId, cb) => {
  return store
    .collection("homes")
    .doc(homeId)
    .collection("transactions")
    .orderBy("timestamp", "desc")
    .onSnapshot(querySnapshot => {
      let transactions = [];
      querySnapshot.forEach(doc => {
        const id = doc.id;
        const data = doc.data();

        transactions.push({
          id,
          ...data
        });
      });
      cb(transactions);
    });
};

export const onGetHomeBalance = (homeId, cb) => {
  return store
    .collection("balances")
    .doc(homeId)
    .onSnapshot(doc => {
      cb(doc.data());
    });
};

export const onGetHomeBalanceLog = (homeId, cb) => {
  return store
    .collection("balances")
    .doc(homeId)
    .collection("log")
    .orderBy("timestamp", "desc")
    .onSnapshot(querySnapshot => {
      let log = [];
      querySnapshot.forEach(doc => {
        const id = doc.id;
        const data = doc.data();

        log.push({
          id,
          ...data
        });
      });
      cb(log);
    });
};

export const setHomeName = (homeId, name) => {
  return new Promise((resolve, reject) => {
    const ref = store.collection("homes").doc(homeId);

    return ref
      .update({ name })
      .then(resolve)
      .catch(reject);
  });
};

export const setHomeIsLive = (homeId, isLive) => {
  return new Promise((resolve, reject) => {
    const ref = store.collection("homes").doc(homeId);

    return ref
      .update({ isLive })
      .then(resolve)
      .catch(reject);
  });
};

export const setHomeColors = (homeId, colors) => {
  return new Promise((resolve, reject) => {
    const ref = store.collection("homes").doc(homeId);

    return ref
      .update({ colors })
      .then(resolve)
      .catch(reject);
  });
};

export const setHomeFonts = (homeId, fonts) => {
  return new Promise((resolve, reject) => {
    const ref = store.collection("homes").doc(homeId);

    return ref
      .update({ fonts })
      .then(resolve)
      .catch(reject);
  });
};

export const setHomeParams = (homeId, params) => {
  return new Promise((resolve, reject) => {
    const ref = store.collection("homes").doc(homeId);

    return ref
      .update({ params })
      .then(resolve)
      .catch(reject);
  });
};

export const setHomeCapabilities = (homeId, capabilities) => {
  return new Promise((resolve, reject) => {
    const ref = store.collection("homes").doc(homeId);

    return ref
      .update({ capabilities })
      .then(resolve)
      .catch(reject);
  });
};

export const setHomeAddress = (homeId, address) => {
  return new Promise((resolve, reject) => {
    const ref = store.collection("homes").doc(homeId);

    return ref
      .update({ address })
      .then(resolve)
      .catch(reject);
  });
};

export const setHomeLogo = (homeId, logo) => {
  return new Promise((resolve, reject) => {
    const ref = store.collection("homes").doc(homeId);

    return ref
      .update({ logo })
      .then(resolve)
      .catch(reject);
  });
};

const generateId = () => {
  return `${new Date().getTime()}_${Math.random()}`;
};

const getDefaultHomeAvailabilityRef = homeId => {
  return store
    .collection("homes")
    .doc(homeId)
    .collection("availability")
    .doc("default");
};

const mergeAvailability = (a1, a2) => {
  const dates = [];

  const a1AsObject = {};
  a1.forEach(day => {
    a1AsObject[day.date] = day;
    if (dates.indexOf(day.date) < 0) {
      dates.push(day.date);
    }
  });

  const a2AsObject = {};
  a2.forEach(day => {
    a2AsObject[day.date] = day;
    if (dates.indexOf(day.date) < 0) {
      dates.push(day.date);
    }
  });

  const merged = [];
  dates.forEach(date => {
    const times = [];

    if (a1AsObject[date]) {
      times.push(...a1AsObject[date].times);
    }
    if (a2AsObject[date]) {
      a2AsObject[date].times.forEach(time => {
        if (times.indexOf(time) < 0) {
          times.push(time);
        }
      });
    }

    merged.push({
      date,
      times: times.sort()
    });
  });

  return merged;
};

export const addAvailability = (homeId, availability) => {
  return new Promise((resolve, reject) => {
    const ref = getDefaultHomeAvailabilityRef(homeId);

    return ref
      .get()
      .then(snapshot => {
        const data = snapshot.data();

        if (data && data.days) {
          const mergedAvailability = mergeAvailability(data.days, availability);

          return ref
            .update({ days: mergedAvailability })
            .then(resolve)
            .catch(reject);
        } else {
          return ref
            .set({ days: availability })
            .then(resolve)
            .catch(reject);
        }
      })
      .catch(reject);
  });
};

export const removeAvailabilityForDay = (homeId, date) => {
  return new Promise((resolve, reject) => {
    const ref = getDefaultHomeAvailabilityRef(homeId);

    return ref
      .get()
      .then(snapshot => {
        const data = snapshot.data();

        if (data && data.days) {
          const updatedDays = data.days.filter(day => {
            return day.date !== date;
          });

          return ref
            .update({ days: updatedDays })
            .then(resolve)
            .catch(reject);
        } else {
          return resolve();
        }
      })
      .catch(reject);
  });
};

export const removeAvailabilityForDayAtTime = (homeId, date, time) => {
  return new Promise((resolve, reject) => {
    const ref = getDefaultHomeAvailabilityRef(homeId);

    return ref
      .get()
      .then(snapshot => {
        const data = snapshot.data();

        if (data && data.days) {
          const updatedDays = data.days.map(day => {
            if (day.date === date) {
              day.times = day.times.filter(_time => {
                return _time !== time;
              });
            }

            return day;
          });

          return ref
            .update({ days: updatedDays })
            .then(resolve)
            .catch(reject);
        } else {
          return resolve();
        }
      })
      .catch(reject);
  });
};

export const getVisit = (homeId, id, cb) => {
  const ref = store
    .collection("homes")
    .doc(homeId)
    .collection("visits");

  return ref.doc(id).onSnapshot(doc => {
    cb(doc.data());
  });
};

export const addVisit = (userId, homeId, date, time, name) => {
  return new Promise((resolve, reject) => {
    const ref = store
      .collection("homes")
      .doc(homeId)
      .collection("visits");

    const id = `D${date}T${time}`;

    return ref
      .doc(id)
      .set({ userId, date, time, name })
      .then(() => {
        resolve(id);
      })
      .catch(reject);
  });
};

export const removeVisit = (homeId, id) => {
  return new Promise((resolve, reject) => {
    const ref = store
      .collection("homes")
      .doc(homeId)
      .collection("visits")
      .doc(id);

    return ref
      .delete()
      .then(resolve)
      .catch(reject);
  });
};

export const getUpcomingVisits = (homeId, cb) => {
  const today = Moment().format("YYYY-MM-DD");

  const ref = store
    .collection("homes")
    .doc(homeId)
    .collection("visits")
    .where("date", ">=", today);
  //.orderBy("timestamp", "asc");

  return ref.onSnapshot(querySnapshot => {
    cb(processVisits(querySnapshot));
  });
};

export const getAllVisits = (homeId, maxCount, cb) => {
  const ref = store
    .collection("homes")
    .doc(homeId)
    .collection("visits")
    .orderBy("date", "asc")
    .limit(maxCount);

  return ref.onSnapshot(querySnapshot => {
    cb(processVisits(querySnapshot));
  });
};

export const getVisits = (homeId, minDate, maxDate, cb) => {
  const ref = store
    .collection("homes")
    .doc(homeId)
    .collection("visits")
    .where("timestamp", ">=", minDate)
    .where("timestamp", "<=", maxDate)
    .where("status.name", "==", "100-complete")
    .orderBy("timestamp", "asc");

  return ref.onSnapshot(querySnapshot => {
    cb(processVisits(querySnapshot));
  });
};

const processVisits = querySnapshot => {
  let visits = [];
  querySnapshot.forEach(doc => {
    const id = doc.id;
    const data = doc.data();

    visits.push({
      id,
      ...data
    });
  });
  return visits;
};

const putTask = (userId, type, action, data, onProgress) => {
  return new Promise((resolve, reject) => {
    let task = {
      userId,
      type,
      action,
      timestamp: serverTimestamp()
    };
    if (data) {
      task.data = data;
    }

    console.info(task);

    store
      .collection("tasks")
      .add(task)
      .then(taskRef => {
        const taskId = taskRef.id;

        if (onProgress) {
          onProgress(taskId);
        }

        const unsubscribe = store
          .collection("tasks")
          .doc(taskId)
          .onSnapshot(doc => {
            const task = doc.data();
            console.log(task);

            if (task && task.result) {
              unsubscribe();

              if (task.result.success) {
                resolve(task.result.data);
              } else {
                reject(task.result.error);
              }
            }
          });
      })
      .catch(reject);
  });
};
