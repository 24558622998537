import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const styles = theme => ({});

class LiveControls extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { isLive, toggleLive, isEditable, classes } = this.props;

    return (
      <div className={classes.root}>
        <ListItem>
          <ListItemIcon>
            {isLive ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </ListItemIcon>
          <ListItemText primary="Live" />
          <ListItemSecondaryAction>
            <Switch
              onChange={toggleLive}
              checked={isLive}
              disabled={isEditable === false}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  } //126
}

export default withStyles(styles)(LiveControls);
