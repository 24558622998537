import React from "react";

import { Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import SetupStep from "./SetupStep";
import CreateHome from "./CreateHome";
import EditAvailability from "./EditAvailability";
import EditFont from "./EditFont";
import EditColors from "./EditColors";
import ShareHome from "./ShareHome";
import EditAddress from "./EditAddress";
import ChangeTierWithStripe from "./ChangeTierWithStripe";
import RedeemCoupon from "../components/RedeemCoupon";

import { toast } from "react-toastify";

import config from "../env";
import PremiumAdvantages from "./PremiumAdvantages";

const styles = (theme) => ({
  header: {
    zIndex: 100,
    position: "sticky",
    top: 0,
    background: "rgba(250,250,250,.85)",
    webkitBackground: "rgba(250,250,250,.75)",
    webkitBackdropFilter: "saturate(180%) blur(20px)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(800)]: {
      minHeight: "calc(100vh - 165px)"
    },
    [theme.breakpoints.up(800)]: {
      minHeight: "calc(100vh - 145px)"
    }
  },
  footer: {
    zIndex: 100,
    position: "sticky",
    bottom: 0,
    padding: 10,
    textAlign: "center",
    background: "rgba(250,250,250,.85)",
    webkitBackground: "rgba(250,250,250,.75)",
    webkitBackdropFilter: "saturate(180%) blur(20px)",
    backdropFilter: "saturate(180%) blur(20px)"
  },
  spacing: {
    height: 20
  },
  button: {
    margin: "0 10px"
  }
});

function getSteps() {
  return [
    "Babynaam",
    "Beschikbaarheid",
    "Stijl en lay-out",
    "Bezoek uitnodigen",
    "Premium worden"
  ];
}

function getStepContent(_this, classes, step) {
  const { home, availability, assets, balance, pricing } = _this.props;

  switch (step) {
    case 0:
      return (
        <SetupStep
          title="Wat is de naam van je kindje?"
          description={`Vul de naam van je kindje hier in. Deze zal gebruikt worden op de welkomstpagina wanneer mensen een bezoek willen plannen.`}
        >
          <CreateHome
            setCreate={(create) => (_this.handleHomeCreate = create)}
          />
        </SetupStep>
      );
    case 1:
      return (
        <SetupStep
          title="Beschikbaarheid toevoegen"
          description="Op welke dagen en tijdstippen wens je bezoek te ontvangen? Je kan je beschikbaarheid steeds aanpassen in het instellingenmenu."
        >
          <EditAvailability home={home} availability={availability} />
        </SetupStep>
      );
    case 2:
      return (
        <SetupStep
          title="Hier kan je de stijl van je pagina aanpassen"
          description="Kies twee themakleuren die bij je pagina passen.
          Je kan dit later aanpassen in 'Instellingen'"
          linkText="Toon mijn publieke pagina"
          linkUrl={`https://${config.hosting.domain}/${
            home ? home.locator : ""
          }`}
        >
          <Typography variant="button">Lettertype</Typography>
          <EditFont home={home} />
          <div className={classes.spacing} />
          <Typography variant="button">Themakleuren</Typography>
          <EditColors home={home} />
        </SetupStep>
      );
    case 3:
      return (
        <SetupStep
          title="Deel je publieke pagina"
          description="Je pagina is klaar! Deel ze nu met familie en vrienden en start met het plannen van het babybezoek!"
        >
          <Typography variant="button">
            Laat je bezoekers je adres weten
          </Typography>
          <EditAddress home={home} />
          <div className={classes.spacing} />
          <Typography variant="button">Deel je publieke pagina</Typography>
          <ShareHome home={home} />
        </SetupStep>
      );
    case 4:
      return (
        <SetupStep
          title="Premium worden (optioneel)"
          description={
            <span>
              <strong style={{ color: "red" }}>
                Je kan Babybezoek onbeperkt gratis gebruiken.
              </strong>
              <br />
              <br />
              Wil je graag extra functionaliteit?
              <br />
              Word dan nu Premium gebruiker! <br />
              <strong>Je Premium abonnement blijft 90 dagen geldig.</strong>
            </span>
          }
        >
          <Typography variant="button">Premium worden (90 dagen)</Typography>
          <div>
            <br />
            <PremiumAdvantages />
          </div>
          <br />
          <ChangeTierWithStripe home={home} pricing={pricing} />
          <div className={classes.spacing} />
          <Typography variant="button">Kortingscode inwisselen</Typography>
          <RedeemCoupon home={home} />
        </SetupStep>
      );
    default:
      return "Onbekend";
  }
}

function isStepOptional(step) {
  return step === 4;
}

class SetupWizard extends React.Component {
  steps = getSteps();

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      skipped: new Set()
    };

    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    const { home } = this.props;
    const { activeStep } = this.state;

    if (home && home.id && activeStep === 0) {
      this.setState({ activeStep: 1 });
    }

    toast.warn(
      "Deze pagina is geoptimaliseerd voor tablets en computers. Mogelijk werkt deze niet optimaal op een smartphone.",
      { autoClose: false }
    );
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    const { home } = this.props;
    const { activeStep } = this.state;

    if (home && home.id && activeStep === 0) {
      this.setState({ activeStep: 1 });
    }
  }

  setActiveStep(activeStep) {
    this.setState({ activeStep });
  }

  setSkipped(skipped) {
    this.setState({ skipped });
  }

  isStepSkipped(step) {
    const { skipped } = this.state;

    return skipped.has(step);
  }

  handleNext() {
    const { activeStep, skipped } = this.state;

    let stepPromise;

    switch (activeStep) {
      case 0:
        stepPromise = this.handleHomeCreate();
        break;
      default:
        stepPromise = new Promise((resolve, reject) => {
          resolve();
        });
    }

    this.setButtonsDisabled(true);
    const toastId = toast("Even geduld...", { autoClose: false });

    stepPromise
      .then((data) => {
        let newSkipped = skipped;
        if (this.isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        this.setActiveStep(activeStep + 1);
        this.setSkipped(newSkipped);

        toast.dismiss(toastId);
        this.setButtonsDisabled(false);
      })
      .catch((error) => {
        console.warn(error);

        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        this.setButtonsDisabled(false);
      });
  }

  handleBack() {
    const { activeStep } = this.state;

    this.setActiveStep(activeStep - 1);
  }

  handleSkip() {
    const { activeStep } = this.state;

    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setActiveStep(activeStep + 1);
    this.setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  }

  handleReset() {
    this.setActiveStep(0);
  }

  setButtonsDisabled(disabled) {
    this.setState({
      backButtonDisabled: disabled === true,
      skipButtonDisabled: disabled === true,
      nextButtonDisabled: disabled === true
    });
  }

  render() {
    const { classes, home } = this.props;
    const {
      activeStep,
      skipped,
      backButtonDisabled,
      skipButtonDisabled,
      nextButtonDisabled
    } = this.state;

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} className={classes.header}>
          {this.steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optioneel</Typography>
              );
            }
            if (this.isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === this.steps.length ? (
            <Redirect to={routes.HOME_VISITS} />
          ) : (
            <div>
              <div className={classes.content}>
                <Typography className={classes.instructions} />
                {getStepContent(this, classes, activeStep)}
              </div>
              <div className={classes.footer}>
                <Button
                  disabled={backButtonDisabled || activeStep <= 1}
                  onClick={this.handleBack}
                  className={classes.button}
                >
                  Vorige
                </Button>
                {false && isStepOptional(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={skipButtonDisabled}
                    onClick={this.handleSkip}
                    className={classes.button}
                  >
                    Overslaan
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  disabled={nextButtonDisabled}
                  onClick={this.handleNext}
                  className={classes.button}
                >
                  {activeStep === this.steps.length - 1
                    ? "Voltooien"
                    : "Volgende"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SetupWizard);
