import React from "react";

import { Typography, Button } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import QRCode from "qrcode.react";

import config from "../env";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  link: {
    textDecoration: "none",
    color: "black"
  },
  homeUrl: {
    fontFamily: "'Anonymous Pro', 'Courier New', Courier, monospace"
  },
  hidden: {
    display: "none"
  }
});

const QR_CANVAS_ID = "homeQR";

class GenerateQRCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.download = this.download.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {}

  download() {
    const canvas = document.getElementById(QR_CANVAS_ID);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      const downloadLink = document.createElement("a");
      downloadLink.crossorigin = "anonymous";
      downloadLink.href = pngUrl;
      downloadLink.download = "qrcode.png";
      //document.body.appendChild(downloadLink);
      downloadLink.click();
      //document.body.removeChild(downloadLink);
    }
  }

  render() {
    const { classes, home, align } = this.props;

    let homeUrl;
    if (home && home.locator) {
      homeUrl = `https://${config.hosting.domain}/${home.locator}`;
    }

    const styles = {
      alignItems: align
        ? align === "left"
          ? "flex-start"
          : align === "right"
          ? "flex-end"
          : "center"
        : "center"
    };

    return (
      <div>
        {homeUrl && (
          <div className={classes.container} style={styles}>
            <QRCode
              className={classes.qr}
              id={QR_CANVAS_ID}
              value={homeUrl}
              size={150}
              level={"H"}
              includeMargin={true}
            />
            <Button onClick={this.download}>Download QR Code</Button>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(GenerateQRCode);
