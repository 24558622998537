import React, { Component } from "react";

import { List, ListItem } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import Moment from "moment";

import SelectDate from "./SelectDate";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  picker: {
    display: "flex"
  }
});

class SelectBeginAndEndDate extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleBeginDateChange = this.handleBeginDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  handleBeginDateChange(beginDate) {
    const { endDate, onChange } = this.props;

    if (beginDate) {
      const newDates = { beginDate, endDate };

      if (beginDate > endDate) {
        newDates.endDate = Moment(beginDate)
          .endOf("day")
          .toDate();
      }

      onChange(newDates);
    }
  }

  handleEndDateChange(endDate) {
    const { beginDate, onChange } = this.props;

    if (endDate) {
      const newDates = { beginDate, endDate };

      if (endDate < beginDate) {
        newDates.beginDate = Moment(endDate)
          .startOf("day")
          .toDate();
      }

      onChange(newDates);
    }
  }

  render() {
    const { classes, beginDate, endDate } = this.props;

    return (
      <div className={classes.root}>
        <List className={classes.stats}>
          <ListItem>
            <SelectDate
              date={beginDate}
              onChange={this.handleBeginDateChange}
              label="VAN"
            />
          </ListItem>
          <ListItem>
            <SelectDate
              date={endDate}
              onChange={this.handleEndDateChange}
              label="TOT EN MET"
            />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(SelectBeginAndEndDate);
