import config from "../env";

let stripe;
try {
  stripe = window.Stripe(config.stripe.pubApiKey);
} catch (error) {
  console.warn(error);
}

export const redirectToCheckout =
  stripe && stripe.redirectToCheckout
    ? stripe.redirectToCheckout
    : new Promise((resolve, reject) => {
        reject("stripe not available");
      });
