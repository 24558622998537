import React from "react";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
  Typography
} from "@material-ui/core";
import DirectionsIcon from "@material-ui/icons/Directions";

import { toast } from "react-toastify";

import { store } from "../firebase";

import { generateRouteUrl } from "../utils";

const styles = theme => ({
  street: {
    flexGrow: 2,
    margin: 2
  },
  number: {
    width: 50,
    margin: 2
  },
  box: {
    width: 50,
    margin: 2
  },
  zip: {
    width: 100,
    margin: 2
  },
  city: {
    flexGrow: 1,
    margin: 2
  },
  buttons: {
    justifyContent: "center"
  },
  directions: {
    marginTop: 20
  }
});

class EditAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      street: "",
      number: "",
      box: "",
      zip: "",
      city: ""
    };

    this.routeDirections = this.routeDirections.bind(this);
  }

  componentDidMount() {
    this.initAddress();
  }

  componentDidUpdate(prevProps, prevState) {
    const { home } = this.props;
    if (
      (prevProps.home === undefined || prevProps.home.address === undefined) &&
      home &&
      home.address
    ) {
      this.initAddress();
    }
  }

  componentWillUnmount() {}

  initAddress() {
    const { home } = this.props;

    const address = {
      street: "",
      number: "",
      box: "",
      zip: "",
      city: ""
    };

    if (home && home.address) {
      if (home.address.street) {
        address["street"] = home.address.street;
      }
      if (home.address.number) {
        address["number"] = home.address.number;
      }
      if (home.address.box) {
        address["box"] = home.address.box;
      }
      if (home.address.zip) {
        address["zip"] = home.address.zip;
      }
      if (home.address.city) {
        address["city"] = home.address.city;
      }
    }

    this.setState({ ...address });
  }

  handleChange = key => event => {
    const val = event.target.value;

    const newState = {};
    newState[key] = val;

    this.setState(newState);
  };

  routeDirections() {
    const { street, number, box, zip, city } = this.state;
    window.open(generateRouteUrl(street, number, box, zip, city), "_blank");
  }

  handleSave = () => {
    const { home } = this.props;

    const toastId = toast("Adres bewaren...", { autoClose: false });

    store
      .setHomeAddress(home.id, this.state)
      .then(() => {
        toast.update(toastId, {
          render: "Adres bewaard",
          type: toast.TYPE.INFO,
          autoClose: 3000
        });
      })
      .catch(error => {
        toast.update(toastId, {
          render: `Er ging iets fout (${error.toString()})`,
          type: toast.TYPE.ERROR,
          autoClose: 5000
        });
        console.warn(error);
      });
  };

  handleCancel = () => {
    this.initAddress();
  };

  render() {
    const { classes, home, showMapsButton } = this.props;
    const { street, number, box, zip, city } = this.state;

    return (
      <List className={classes.list}>
        <ListItem>
          <TextField
            margin="dense"
            id="street"
            label="Straat"
            fullWidth
            value={street}
            className={classes.street}
            onChange={this.handleChange("street")}
          />
          <TextField
            margin="dense"
            id="number"
            label="Nr."
            value={number}
            className={classes.number}
            onChange={this.handleChange("number")}
          />
          <TextField
            margin="dense"
            id="box"
            label="Bus"
            value={box}
            className={classes.box}
            onChange={this.handleChange("box")}
          />
        </ListItem>
        <ListItem>
          <TextField
            margin="dense"
            id="zip"
            label="Postcode"
            value={zip}
            className={classes.zip}
            onChange={this.handleChange("zip")}
          />
          <TextField
            margin="dense"
            id="city"
            label="Gemeente of stad"
            fullWidth
            value={city}
            className={classes.city}
            onChange={this.handleChange("city")}
          />
        </ListItem>
        <ListItem className={classes.buttons}>
          <Button
            color="primary"
            className={classes.button}
            onClick={this.handleSave}
          >
            Bewaren
          </Button>
          <Button
            color="secondary"
            className={classes.button}
            onClick={this.handleCancel}
          >
            Annuleren
          </Button>
        </ListItem>
        {showMapsButton && (
          <ListItem
            button
            onClick={this.routeDirections}
            className={classes.directions}
          >
            <ListItemIcon>
              <DirectionsIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="button">Tonen in Google Maps</Typography>
              }
            />
          </ListItem>
        )}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditAddress);
