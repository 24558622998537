import React from "react";
import { withRouter } from "react-router-dom";

import AuthContext from "./authContext";

import { Redirect } from "react-router-dom";

import * as routes from "../constants/routes";

const withHomeAuthorization = authCondition => Component => {
  class withHomeAuthorization extends React.Component {
    componentDidMount() {
      /*firebase.auth.onAuthStateChanged(authUser => {
        if (!authCondition(authUser)) {
          this.props.history.push(routes.SIGN_IN);
        }
      });*/
    }

    /*render() {
      return (
        <Component />
      );
    }*/

    render() {
      return (
        <AuthContext.Consumer>
          {auth =>
            auth && auth.user && !auth.user.isAnonymous ? (
              auth.crew ? (
                <Component auth={auth} {...this.props} />
              ) : (
                <Redirect to={routes.HOME_SETUP} />
              )
            ) : (
              <Redirect to={routes.HOME_SIGN_IN} />
            )
          }
        </AuthContext.Consumer>
      );
    }
  }

  return withRouter(withHomeAuthorization);
};

export default withHomeAuthorization;
