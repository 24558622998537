import React from "react";
import classNames from "classnames";

import { Typography } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import InfiniteCalendar, {
  Calendar,
  defaultMultipleDateInterpolation,
  withMultipleDates
} from "react-infinite-calendar";

import Moment from "moment-timezone";

import { jsDateToDateString } from "../utils";

import SelectTimes from "../components/SelectTimes";

const styles = theme => ({
  pickers: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down(732)]: {
      flexWrap: "wrap"
    }
  },
  picker: {
    margin: 15
  },
  times: {
    [theme.breakpoints.down(732)]: {
      width: 410
    }
  }
});

class AddAvailabilityWithCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleTimesChange = this.handleTimesChange.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleDateSelect(date) {
    const { dates, times, onChange } = this.props;
    const newDates = [...dates];

    const dateAsString = jsDateToDateString(date);

    const index = newDates.indexOf(dateAsString);
    
    if (index >= 0) {
      newDates.splice(index, 1);
    } else {
      newDates.push(dateAsString);
    }

    if (onChange) {
      onChange({ dates: newDates, times });
    }
  }

  handleTimesChange(times) {
    const { dates, onChange } = this.props;

    if (onChange) {
      onChange({ dates, times });
    }
  }

  render() {
    const { classes, dates, times } = this.props;

    const theme = {
      /*selectionColor: "rgb(146, 118, 255)",
      textColor: {
        default: "#333",
        active: "#FFF"
      },
      weekdayColor: "rgb(146, 118, 255)",
      headerColor: "rgb(127, 95, 251)",
      floatingNav: {
        background: "rgba(81, 67, 138, 0.96)",
        color: "#FFF",
        chevron: "#FFA726"
      }*/
    };

    /*if (home) {
      if (home.colors) {
        if (home.colors.primary) {
          theme.selectionColor = home.colors.primary;
        }
        if (home.colors.secondary) {
        }
      }
    }*/

    return (
      <div className={classes.pickers}>
        <div>
          <div className={classes.picker}>
            <Typography variant="overline">Op volgende dagen...</Typography>
            <InfiniteCalendar
              locale={{
                locale: require("date-fns/locale/nl"),
                weekStartsOn: 1,
                headerFormat: "dddd, D MMM",
                weekdays: ["Zo", "Ma", "Di", "Woe", "Do", "Vrij", "Za"],
                blank: "Geen datum geselecteerd",
                todayLabel: {
                  long: "Vandaag",
                  short: "Nu"
                }
              }}
              displayOptions={{
                showHeader: false
              }}
              theme={theme}
              height={300}
              Component={withMultipleDates(Calendar)}
              interpolateSelection={defaultMultipleDateInterpolation}
              selected={dates}
              onSelect={this.handleDateSelect}
            />
          </div>
        </div>
        <div className={classNames(classes.picker, classes.times)}>
          <Typography variant="overline">
            ... wil ik op deze tijdstippen bezoek ontvangen
          </Typography>
          <SelectTimes times={times} onChange={this.handleTimesChange} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AddAvailabilityWithCalendar);
