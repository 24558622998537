import React from "react";

import FontPicker from "font-picker-react";
import config from "../env";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  picker: {
    display: "none"
  }
});

const withFont = name => Component => {
  class WithFont extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {}

    render() {
      const { classes, home, ...other } = this.props;

      return (
        <div>
          {home && home.fonts && home.fonts[name] && (
            <div className={classes.picker}>
              <FontPicker
                apiKey={config.webFonts.apiKey}
                activeFontFamily={home.fonts[name].family}
                limit={120}
              />
            </div>
          )}
          <Component home={home} {...other} />
        </div>
      );
    }
  }

  return withStyles(styles)(WithFont);
};

export default withFont;
