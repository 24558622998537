import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";

import { SwatchesPicker, BlockPicker } from "react-color";

import { toast } from "react-toastify";

import { store } from "../firebase";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  colorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 20
  },
  colorPickerContainer: {
    marginTop: 10
  },
  backgroundButton: {
    backgroundColor: theme.palette.secondary.main
  }
});

class EditColors extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handlePrimaryColorChange = this.handlePrimaryColorChange.bind(this);
    this.handleSecondaryColorChange = this.handleSecondaryColorChange.bind(
      this
    );
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handlePickColor(type) {
    const { home } = this.props;

    if (type === this.state.type) {
      this.setState({ type: null, color: null });
    } else {
      this.setState({ type, color: home.colors[type] });
    }
  }

  handlePrimaryColorChange(color) {
    return this.handleColorChange("primary", color.hex);
  }

  handleSecondaryColorChange(color) {
    return this.handleColorChange("secondary", color.hex);
  }

  handleColorChange(type, color) {
    const { home } = this.props;

    let colors = { ...home.colors };
    colors[type] = color;

    store
      .setHomeColors(home.id, colors)
      .then(() => {})
      .catch(error => {
        toast.error("Fout bij aanpassen van de kleur");
        console.warn(error);
      });

    this.setState({ type: null, color: null });
  }

  render() {
    const { classes, home } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.colorContainer}>
          <Button
            color="primary"
            className={classes.backgroundButton}
            onClick={() => {
              this.handlePickColor("primary");
            }}
          >
            Voorgrondkleur
          </Button>
          {home && home.colors && home.colors.primary && (
            <div className={classes.colorPickerContainer}>
              <SwatchesPicker
                color={home.colors.primary}
                onChangeComplete={this.handlePrimaryColorChange}
              />
            </div>
          )}
        </div>
        <div className={classes.colorContainer}>
          <Button
            color="primary"
            className={classes.backgroundButton}
            onClick={() => {
              this.handlePickColor("secondary");
            }}
          >
            Achtergrondkleur
          </Button>
          {home && home.colors && home.colors.secondary && (
            <div className={classes.colorPickerContainer}>
              <SwatchesPicker
                color={home.colors.secondary}
                onChangeComplete={this.handleSecondaryColorChange}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditColors);
